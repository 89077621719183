import React from 'react';

import {
  Create,
  Filter,
  Pagination,
  SearchInput,
  Show,
  ShowButton,
  SimpleForm,
  TabbedShowLayout,
  Tab,
  TextField,
  TextInput,
  required,
  translate
} from 'react-admin';

import {
  DefaultCardActions,
  DissociateButton,
  ReferenceManyManyField,
  Edit,
  RelationShow,
  Relation,
  ResponsiveList,
  ResponsiveDatagrid
} from '../../components';

import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Group';

export const TeamIcon = Icon;
const listStyles = {};

const TeamFilter = translate(({translate, ...props}) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
  </Filter>
));

export const TeamList = withStyles(listStyles)(({ classes, ...props }) => (
  <ResponsiveList
    {...props}
    listFilters={<TeamFilter />}
    recordActions={[<ShowButton />]}
    rowClick="show"
  >
    <TextField source="name" />
    <TextField source="code" />
    <TextField source="description" />
  </ResponsiveList>
));

const editStyles = {
  name: { display: 'inline-block' },
  code: { display: 'inline-block', marginLeft: 32 },
};

export const TeamEdit = withStyles(editStyles)(({ classes, ...props }) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={required()}
      />
      <TextInput
        source="code"
        validate={required()}
      />
      <TextInput
        source="description"
        validate={required()}
      />
    </SimpleForm>
  </Edit>
));

export const TeamCreate = withStyles(editStyles)(({ classes, ...props }) => (
  <Create actions={<DefaultCardActions/>} {...props}>
    <SimpleForm redirect='show'>
      <TextInput
        source="name"
        validate={required()}
      />
      <TextInput
        source="code"
      />
      <TextInput
        source="description"
      />
    </SimpleForm>
  </Create>
));

export const TeamShow = withStyles(editStyles)(({ classes, ...props }) => (
  <Show actions={<DefaultCardActions {...props} hasCreate={false}/>} {...props}>
    <TabbedShowLayout>
      <Tab label='resources.teams.tabs.details'>
        <TextField source="name" />
        <TextField source="code" />
        <TextField source="description" />
      </Tab>
      <Tab label='resources.teams.tabs.users'>
        <ReferenceManyManyField source='teams' reference='users' addLabel={false} fullWidth pagination={<Pagination/>}>
          <RelationShow reference='users'
            dialogContent={<Relation basePath='teams' recordId={props.id}  pagination={<Pagination/>}>
              <ResponsiveDatagrid>
                <TextField source='username'/>
                <TextField source='email'/>
              </ResponsiveDatagrid>
            </Relation>}
          >
            <ResponsiveDatagrid actions={[<DissociateButton basePath='teams' basePathId={props.id}/>]}>
              <TextField source='username'/>
              <TextField source='email'/>
            </ResponsiveDatagrid>
          </RelationShow>
        </ReferenceManyManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
));