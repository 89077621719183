import React, {Component} from 'react';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import {
  ArrayInput,
  Button,
  BooleanField,
  BooleanInput,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  addField,
  fetchEnd,
  fetchStart,
  required,
  GET_LIST
} from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import { arrayPush, arrayInsert, arrayRemove, clearFields } from 'redux-form';
import TableFormIterator from './TableFormIterator';

import { dataProvider } from '../../providers';

import {updateRelatedProperties as updateRelatedPropertiesAction} from './actions';

//Listens for changes in form [TableFormIterator].observedPropertyId field and passes it ReferenceField as record, so it can fetch and show it
const CustomRefField = (props) => {
  return <ReferenceField label='resources.observedProperty.name' basePath='/observedProperties' source='observedpropertyId' reference='observedProperties' record={{observedpropertyId: props.input.value}}>
    <TextField source="name" />
  </ReferenceField>
};
const CustomRefFieldCon = addField(CustomRefField);

class RelateThroughInput extends Component {
  state = {
    relatedProperties: []
  }

  componentDidMount(){
    this.updateProperties();
  }

  updateProperties = () => {
    this.props.arrayInsert('record-form','properties', 0, {
      visible: true,
      name: "EquipmentId",
      unitOfMeasurement: 'N/A',
      index: 0
    });

    const { id } = this.props && this.props.record;
    // If has ID then fetch properties
    if (id) {
      dataProvider(GET_LIST, 'modelproperties', { pagination: { perPage: -1 }, sort: { field: 'index', order: 'ASC' }, filter: { equipmentmodelId: id } })
      .then(resp => {
          this.props.saveRelatedProperties(resp.data);
          resp.data && resp.data.forEach((property, index)=> {
            this.props.arrayInsert('record-form', 'properties', index+1, property);
          });
          // fetchEnd();
        }).catch(error => {
          console.error('Error in fetch equipment model', error)
          // fetchEnd();
        });
    }
  };

  onAdd = () => {
    if(this.state.selectedModelId){
      this.props.arrayPush('record-form', 'properties', {
        observedpropertyId: this.state.selectedModelId
      });
      this.props.clearFields('record-form', false, true, 'observedProperty');
    }
  };

  onChange = (_, id) => {
    this.setState({selectedModelId: id});
  };

  onRowRemove = (index) => {
    this.props.arrayRemove('record-form', 'properties', index);
  };

  firstRowFields = () => {
    return [
      <NumberField source='index' />,
      <TextField source='name'/>,
      <TextField source='unitOfMeasurement' />,
      <BooleanField source="visible" />,
    ]
  };

  render(){
    return <div>
      <ReferenceInput style={{width:'16%'}} perPage={-1} label={`resources.${this.props.resource}.fields.observedProperty`}
        source='observedProperty' reference="observedProperties" resource='observedProperties'
        sort={{field: 'name', order: 'ASC'}} onChange={this.onChange}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <Button style={{marginLeft: '16px'}} label='ra.action.add' onClick={this.onAdd}><AddIcon/></Button>
      <ArrayInput source='properties' resource='equipmentmodels'>
        <TableFormIterator firstRow={this.firstRowFields()} record={this.state && this.state.record} onRowRemove={(index) => this.onRowRemove(index)}>
          <NumberInput source='index' validate={required()}/>
          <CustomRefFieldCon source='observedpropertyId' />
          <TextInput source='unitOfMeasurement' />
          <BooleanInput source="visible" label='' />
        </TableFormIterator>
      </ArrayInput>
    </div>
  }
}

RelateThroughInput.defaultProps = {
  relatedproperties: [],
}

const mapDispatchToProps = dispatch => ({
  saveRelatedProperties: data => dispatch(updateRelatedPropertiesAction(data))
});

const enhance = compose(  
  connect(null, mapDispatchToProps),
  connect(null, {
    arrayInsert,
    arrayPush,
    arrayRemove,
    clearFields,
    fetchEnd,
    fetchStart
  })
);

export default enhance(RelateThroughInput);
