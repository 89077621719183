import { NavLink } from 'react-router-dom'
import React, { Component } from 'react';
import withBreadcrumbs from './components/breadcrumbs/Breadcrumbs'

const Breadcrumbs = withBreadcrumbs()(({ breadcrumbs }) => (
  <div style={{ paddingBlock: 15 }}>
    {breadcrumbs
      .map(({ match, breadcrumb }, index) => (
        <Breadcrumb key={match.url} breadcrumb={breadcrumb} url={match.url} isLast={index === breadcrumbs.length - 1} />
      ))
      .reduce((prev, curr, currentIndex) => [prev, <Separator key={currentIndex} />, curr]) // Add separators between crumbs
    }
  </div>
));

class Breadcrumb extends Component {

  constructor(props) {
    super(props)
    this.state = { hover: false };
  }

  handleOnEnter = () => {
    this.setState({ hover: true });
  }

  handleOnExit = () => {
    this.setState({ hover: false });
  }

  render() {
    const { breadcrumb, url, isLast } = this.props
    return (
      <span  >
        <NavLink to={url} onMouseEnter={this.handleOnEnter} onMouseLeave={this.handleOnExit}
          style={{
            color: isLast ? 'black' : 'grey',
            textDecoration: this.state.hover ? 'underline' : 'none'
          }}
        >{breadcrumb}</NavLink>
      </span>
    )
  }
}

class Separator extends Component {

  render() {
    return (
      <span style={{ color: 'grey' }}> / </span>
    )
  }
}

export default Breadcrumbs;