import React from 'react';
import { Button } from 'react-admin';import { Link } from 'react-router-dom';
import {
  Map as MapIcon
} from '@material-ui/icons'
const MapButton = ({ record }) => {
  return (
    <Button
      component={Link}
      to={`/maps/history/${record.id}`}
      label='pos.general.map'
    >
      <MapIcon />
    </Button>
  );
};

export default MapButton;