import React from 'react';

import {
  BooleanField,
  BooleanInput,
  Create,
  Filter,
  NullableBooleanInput,
  ReferenceInput,
  ReferenceField,
  SearchInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';

import {
  maxLength,
  minLength,
  required,
  translate
} from 'react-admin';

import {
  Edit,
  matchingPasswords,
  DefaultCardActions,
  ResponsiveList
} from '../../components';

import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Person';

import EditActions from './EditActions';

export const UserIcon = Icon;

// EXAMPLE - REMOVE
const listStyles = {};

const UserFilter = translate(({translate, ...props}) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
    <NullableBooleanInput source="active" />
  </Filter>
));

export const UserList = withStyles(listStyles)(({ permissions, classes, ...props }) => (
  <ResponsiveList
    {...props}
    listFilters={<UserFilter />}
    recordActions={[<ShowButton />]}
    rowClick="show"
  >
    <TextField source="username" />
    <TextField source="name" />
    <TextField source="email" />
    <BooleanField source="active" />
    {
      permissions === "sysadmin" && <ReferenceField source="institutionId" reference="institutions" linkType="show" allowEmpty>
        <TextField source="name"/>
      </ReferenceField>
    }
    <RoleField source="role" addLabel={true}/>
  </ResponsiveList>
));

const editStyles = {
    name: { display: 'inline-block' },
    username: { display: 'inline-block', marginLeft: 32 },
    email: { width: 544 }
};

const RoleInput = ({ record, resource, source, translate, ...rest}) => {
  let isAdmin = false;
  if(record && adminRoles.includes(record[source])) {
    isAdmin = true;
  }
  return <BooleanInput label={`resources.${resource}.fields.${source}`} source='isAdmin' defaultValue={isAdmin} />
}

export const UserEdit = withStyles(editStyles)(({ permissions, classes, ...props }) => (
  <Edit actions={<EditActions {...props}/>} {...props}>
    <SimpleForm>
      <TextInput
          source="username"
          validate={required()}
      />
      <TextInput
          source="name"
          validate={required()}
      />
      <TextInput
          type="email"
          source="email"
          validate={required()}
          validation={{ email: true }}
          formClassName={classes.email}
      />
      {
        permissions === 'sysadmin' && <ReferenceInput validate={required()} source="institutionId" reference="institutions">
          <SelectInput optionText="name" />
        </ReferenceInput>
      }
      <BooleanInput source="active" />
      <RoleInput source="role" />
    </SimpleForm>
  </Edit>
));

export const UserCreate = withStyles(editStyles)(({ permissions, classes, ...props }) => (
  <Create actions={<DefaultCardActions/>} {...props}>
    <SimpleForm redirect='show'>
      <TextInput
          source="username"
          validate={required()}
      />
      <TextInput
          source="name"
          validate={required()}
      />
      <TextInput
          type="email"
          source="email"
          validate={required()}
          validation={{ email: true }}
      />
      {
        permissions === 'sysadmin' && <ReferenceInput source="institutionId" validate={required()} reference="institutions">
          <SelectInput optionText="name" />
        </ReferenceInput>
      }
      <TextInput
          source="password"
          type="password"
          validate={[required(), minLength(5), maxLength(32), matchingPasswords()]}
      />
      <TextInput
          source="rewrite_password"
          type="password"
          validate={[required(), minLength(5), maxLength(32), matchingPasswords()]}
      />
      <BooleanInput source="isAdmin" />
    </SimpleForm>
  </Create>
));

const adminRoles = [ 'sysadmin', 'admin']

const RoleField = ({ record, source, ...rest}) => {
  const admin = { isAdmin: false }
  if(record !== undefined && adminRoles.includes(record[source])) {
    admin.isAdmin = true
  }
  return <BooleanField source='isAdmin' record={admin} />
}

export const UserShow = withStyles(editStyles)(({ permissions, classes, ...props }) => (
  <Show actions={<DefaultCardActions {...props} hasCreate={false}/>} {...props}>
    <SimpleShowLayout>
      <TextField source="username" />
      <TextField source="name" />
      <TextField source="email" />
      <BooleanField source="active" />
      <RoleField source="role" addLabel={true}/>
      {
        permissions === "sysadmin" && <ReferenceField source="institutionId" reference="institutions" linkType="show" allowEmpty>
          <TextField source="name"/>
        </ReferenceField>
      }
    </SimpleShowLayout>
  </Show>
));
