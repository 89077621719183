import React from 'react';
import {
  Create,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextInput,
  TextField,
  translate,
  required
} from 'react-admin';

import {
  DefaultCardActions,
  ResponsiveList,
  ResponsiveDatagrid,
  Edit
} from '../../components';

import CustomSimpleForm from './CustomSimpleForm';
import RelateThroughInput from './RelateThroughInput';
import RefModelProp from './RefModelProp';
export { default as EquipmentModelIcon} from '@material-ui/icons/CropSquare';

const createFormValidation = (translate, record) => {
  const errors = {};
  const currentIndexes = [];

  record.properties && record.properties.forEach((property, index) => {
    let error;
    // To ignore index 0 - Equipment Id
    if (index > 0 && property.index !== undefined) {
      // Check if index is duplicated
      if(currentIndexes.includes(property.index))
        error = translate('resources.equipmentmodels.errors.duplicate_index');
      // Check for indexes lower than
      if (property.index < 1)
        error = translate('resources.equipmentmodels.errors.index_lt1');
      
      currentIndexes.push(property.index);
      if (error) {
        if(!errors.properties) errors.properties = [];
        if(!errors.properties[index]) errors.properties[index] = {};
        if(!errors.properties[index].index) errors.properties[index].index = [];

        errors.properties[index].index.push(error);
      } 
    }
  });
  return errors;
}

export const EquipmentModelList = ({permissions, ...props}) => (
  permissions === 'sysadmin'
    ? <ResponsiveList
        recordActions={[<ShowButton />]}
        rowClick="show"
        {...props}>
        <TextField source='name' />
        <ReferenceField source="equipmenttypeId" reference="equipmenttypes" linkType="show">
          <TextField source="name"/>
        </ReferenceField>
      </ResponsiveList>
    : null
);

export const EquipmentModelCreate = translate(({permissions, translate, ...props}) => {
  return permissions === 'sysadmin' ? 
  <Create actions={<DefaultCardActions/>} {...props}>
    <CustomSimpleForm validate={(values) => createFormValidation(translate, values)}>
      <TextInput source="name" validate={required()} />
      <ReferenceInput source="equipmenttypeId" reference="equipmenttypes" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <RelateThroughInput/>
    </CustomSimpleForm>
  </Create> : null
});

export const EquipmentModelEdit = translate(({permissions, translate, ...props}) => {
  return permissions === 'sysadmin' ? <Edit {...props}>
    <CustomSimpleForm validate={(values) => createFormValidation(translate, values)}>
      <TextInput source="name" validate={required()} />
      <ReferenceInput source="equipmenttypeId" reference="equipmenttypes" validate={required()}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <RelateThroughInput />
    </CustomSimpleForm>
  </Edit> : null
});

const CustomRefField = ({children, ...props}) => {
  return props.record.id === -1 ? <TextField source="name" record={props.record}/> : <ReferenceField {...props}>
    {children}
  </ReferenceField>
}

export const EquipmentModelShow = translate(({permissions, translate, ...props}) => {
  return permissions === 'sysadmin' ? <Show actions={<DefaultCardActions {...props} hasCreate={false}/>} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ReferenceField source="equipmenttypeId" reference="equipmenttypes">
        <TextField source="name"/>
      </ReferenceField>
      <RefModelProp>
        <ResponsiveDatagrid>
          <TextField source="index" sortable={false}/>
          <CustomRefField basePath="/observedProperties" source="observedpropertyId" reference="observedProperties" linkType="show">
            <TextField source="name" sortable={false} />
          </CustomRefField>
          <TextField source="unitOfMeasurement" sortable={false} />
          <BooleanField source="visible" sortable={false} />
        </ResponsiveDatagrid>
      </RefModelProp>
    </SimpleShowLayout>
  </Show> : null
})