import React, { Component } from 'react';

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  translate,
  showNotification
} from 'react-admin';

import {
  PlayArrow as PlayArrowIcon,
  Pause as PauseIcon
} from '@material-ui/icons';

import {
  Tooltip,
  IconButton
} from '@material-ui/core';

import { refreshView } from 'ra-core';

import { SimpleModal } from '../../components';
import { S3_ENDPOINT } from '../../Configuration';

class ViewFile extends Component {
  audio = this.props.record.file ? new Audio(`${S3_ENDPOINT}${this.props.record.file}`) : null;
  state = {
    modalOpen: false,
    isPlaying: false,
    extension: this.props.record.file?.split('.').pop() || ""
  };

  componentDidMount() {
    if(this.audio)
      this.audio.addEventListener('ended', () => this.setState({ isPlaying: false }));
  }

  handleAudio = () => {
    const isPlaying = this.state.isPlaying;
    this.state.isPlaying ? this.audio.pause() : this.audio.play();
    this.setState({ isPlaying: !isPlaying });
  }

  handleImage = () => {
    this.setState({ modalOpen: true });
  }

  render() {

    const { record } = this.props;
    return record.file ? (
      <>
        <SimpleModal open={this.state.modalOpen} onClose={() => this.setState({ modalOpen: false })} maxWidth={'md'}>
          <img
            alt={record.file}
            src={`${S3_ENDPOINT}${record.file}`}
            style={{ width: '100%' }}
          />
        </SimpleModal>
        {
          ['jpg', 'jpeg'].includes(this.state.extension) &&
          <Tooltip title={'Open'}>
            <img
              alt={record.file}
              onClick={this.handleImage}
              src={`${S3_ENDPOINT}${record.file}`}
              style={{ cursor: 'pointer', width: '30px' }}
            />
          </Tooltip>

        }
        {
          ['flac', 'wav'].includes(this.state.extension) &&
          <Tooltip title={this.state.isPlaying ? 'Pause' : 'Play'}>
            <IconButton onClick={this.handleAudio}>
              {this.state.isPlaying ? <PauseIcon></PauseIcon> : <PlayArrowIcon></PlayArrowIcon>}
            </IconButton>
          </Tooltip>
        }
      </>
    ) : null;
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification,
    refreshView
  })
);

export default enhance(ViewFile);