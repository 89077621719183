import React from 'react';
import { Route } from 'react-router-dom';

import {
  Configuration,
  DatasetMap,
  DatasetChart,
  DerivedSetChart,
  DerivedSetMap,
  EquipObs,
  History,
  Live,
  Manual,
  Profile,
  SearchObs
}  from './containers';

export default [
  <Route exact path="/maps/live" component={Live} />,
  <Route exact path="/maps/history" component={History} />,
  <Route exact path="/maps/history/:launchId" component={History} />,
  <Route exact path="/datasets/:id/map" component={DatasetMap} />,
  <Route exact path="/datasets/:id/charts" component={DatasetChart} />,
  <Route exact path="/derivedsets/:id/map" component={DerivedSetMap} />,
  <Route exact path="/derivedsets/:id/charts" component={DerivedSetChart} />,
  <Route exact path="/profile" component={Profile} />,
  <Route exact path="/configuration" component={Configuration} />,
  <Route exact path="/manual" component={Manual} />,
  <Route exact path="/equipment/:id/observations" component={EquipObs} />,
  <Route exact path="/search" component={SearchObs} />
];
