import portugueseMessages from 'ra-language-portuguese-pt';

export default {
  ...portugueseMessages,
  errors: {
    SOMETHING: 'Ocorreu um erro',
    LOCALSTORAGE_FULL: 'O LocalStorage está cheio, por favor apague os dados',
    '404': 'Não é possível dar resposta ao pedido',
    '422': 'Erro de validação dos campos no servidor',
    ALREADY_EXISTS: 'Recurso já existe',
    AUTHORIZATION_REQUIRED: 'Precisa de autorização para aceder à página',
    LOGIN_FAILED: 'Login falhou',
    INSTITUTION_MISSING: 'Instituição em falta'
  },
  sockets: {
    build_success: 'O lançamento %{launchName} está atualizado!',
    build_fail: 'Ocorreu um erro com o lançamento %{launchName}',
    wave_parameters_success: 'Calculo dos wave parameters %{waveParamId} concluido',
    wave_parameters_fail: 'Ocorreu um erro com o calculo dos wave parameters %{waveParamId}',
    file_process_success: 'Ficheiro %{name} processado',
    file_process_fail: 'Ficheiro %{name} falhou',
    file_process_duplicated: 'Ficheiro %{name} duplicado',
    update_data: 'Dados atualizados'
  },
  dates: {
    not_defined: 'Data não definida'
  },
  pos: {
    search: 'Procurar',
    configuration: 'Configuração',
    language: {
      name: 'Linguagem',
      'en-GB': 'English',
      pt: 'Português',
    },
    manual: 'Manual',
    servicedesk: 'Service Desk',
    theme: {
      name: 'Tema',
      light: 'Claro',
      dark: 'Escuro',
    },
    update: {
      name: 'Atualizações em tempo real',
      yes: 'Sim',
      no: 'Não',
    },
    submenu: {
      datasets: 'Datasets',
      equipment: 'Equipamento'
    },
    dashboard: {
      campaign_count: 'Campanhas disponíveis',
      last_campaign: 'Campanha mais recente',
      welcome: {
        title: 'WAVY Operation Software',
        subtitle: "Bem-vindo ao WAVY Operation Software. Explore este software e submeta as suas sugestões.",
      },
    },
    profile: {
      profile: 'Perfil',
      profile_updated: 'Perfil atualizado com sucesso.',
      profile_update_error: 'Erro ao atualizar o perfil.',
      old_password: 'Senha actual',
      new_password: 'Nova senha',
      repeat_password: 'Repetir senha',
      change_password: 'Mudar senha',
      different_passwords: 'Repeat password not equal to new password',
      password_changed: 'Senha alterada com sucesso'
    },
    general: {
      add_referencing: 'Adicionar %{reference}',
      remove_referencing: 'Remover %{reference}',
      add_button: 'Adicionar',
      remove_button: 'Remover',
      select_all: 'Selecionar tudo',
      unselect_all: 'Desselecionar tudo',
      map: 'Mapa',
      catalogue: 'Catalogo',
      publish: 'Publicar',
      delete: 'Apagar',
      re_publish: 'Re-publicar',
      publish_failed: 'Publicação dos dados falhou',
      publish_success: 'Publicação completa com sucesso',
      delete_success: 'Publicação apagada',
      delete_failed: 'Falha a apagar a publicação',
      table: 'Tabela',
      chart: 'Gráfico',
      update: 'Atualizar',
      open: 'Aberto',
      closed: 'Fechado',
      active: 'Ativo',
      inactive: 'Inativo',
      in_use: 'Em uso',
      available: 'Disponível',
      edit: 'Editar',
      list: 'Lista',
      export: 'Exportar'
    },
    derived: {
      to_dts: 'a Dataset derivado',
      searchLabel: 'Nome'
    },
    info: {
      one_selectable: 'Este botão não tem funcionalidade, somente um registo pode ser selecionado.'
    },
    annotation:{
      clicked: 'Clique novamente no gráfico para criar uma anotação.',
      created: 'Anotação criada.',
      loading: 'A carregar dados'
    }
  },
  containers: {
    annotations: {
      modalTitle: "Anotação",
      modalEdit: "Editar anotação",
      next: "PROX",
      begin: "Data de inicio",
      time: "Timestamp",
      record: "Record",
      tags: "Tags",
      description: 'Descrição',
      end: "Data de fim",
      choose: "Escolha o tempo de %{hour}",
      need_be_bigger: "Selecione um momento depois do tempo de Inicio",
      observations: "observações",
      tag_undefined: "Esta anotação não tem tags",
      show_all: "Anotações"
    },
    parameters: {
      modalTitle: "Calcular parametros",
      next: "PROX",
      begin: "Inicio",
      end: "Fim",
      choose: "Escolha o tempo de %{hour}",
      need_be_bigger: "Selecione um momento depois do tempo de Inicio",
      observations: "observações",
      success: "Começou o cálculo",
      observationsNumber: "Número de observações",
      timeLimit: "Intervalo de tempo",
      timeUnit: "Minutos",
    },
    maps: {
      history: {
        name: 'Mapa Histórico',
        complete_interval: 'Intervalo completo',
        all_data: 'Todos os dados',
        info: {
          no_data: 'Não existem dados disponíveis!'
        }
      },
      live: {
        name: 'Mapa ao Vivo',
      },
      datasets: {
        name: 'Mapa de Datasets'
      },
    },
    derivedsets: {
      map: {
        name: 'Mapa de Datasets Derivados',
        loadObsError: 'Erro a carregar dados'
      },
      chart: {
        name: 'Gráficos de Datasets Derivados'
      }
    },
    search: {
      name: 'Pesquisar',
      fields: {
        equipment: 'Equipamento',
        beginTimestamp: 'Tempo de início',
        endTimestamp: 'Tempo de fim'
      },
      errors: {
        dates: 'O intervalo de tempo é inválido, timestamp inicial mais recente que timestamp final.'
      }
    },
    charts: {
      name: 'Gráficos',
      unloadable: 'Não foi possível carregar o gráfico. Poderão não existir dados.'
    },
    properties: {
      altitude: 'Altitude',
      battery: 'Bateria',
      date: 'Data',
      hdop: 'Hdop',
      latitude: 'Latitude',
      longitude: 'Longitude',
      no_data: 'Sem dados',
      rarc: 'RARC',
      satellites: 'Satélites',
      speed: 'Velocidade',
      timestamp: 'Hora',
      rssi: 'rssi'
    },
    manual: {
      addSection: 'Adicionar Secção',
      addSubSection: 'Adicionar Subsecção',
      title: '%{action} %{type}',
      fields: {
        title: 'Título',
        section: 'Secção',
        subsection: 'Subsecção',
      },
      error: {
        invalid_number: 'Tem de ser um número positivo maior que 0'
      }
    }
  },
  inputs: {
    coordinates: {
      latitude: 'Latitude',
      longitude: 'Longitude'
    },
    error: {
      invalid_latitude: 'Latitude tem de ser um valor entre -90 and 90',
      invalid_longitude: 'Longitude tem de ser um valor entre -180 and 180'
    },
    general: {
      update: 'Elemento atualizado',
      create: 'Elemento criado',
      delete: 'Elemento apagado',
    }
  },
  error: {
    INVALID_PASSWORD: 'A password atual está incorreta',
    CAMPAIGN_OPEN_LAUNCH: 'A campanha já tem um lançamento aberto',
    CAMPAIGN_NOT_FOUND: 'A campanha não foi encontrada',
    UNAUTHORIZED: 'Sem autorização',
    USER_IS_SYSADMIN: 'O utilizador é o administrador de sistema',
    USER_NOT_FOUND: 'O utilizador não foi encontrado',
    LAUNCH_NOT_FOUND: 'O lançamento não foi encontrado',
    BEGIN_TIMESTAMP_LATER: 'O tempo de início tem de ser anterior ao tempo de fim',
    END_TIMESTAMP_EARLIER: 'O tempo de fim tem de ser posterior do tempo de início',
    BUILDING_ERROR: 'Erro a fazer o BUILD'
  },
  resources: {
    campaigns: {
      name: 'Campanha |||| Campanhas',
      fields: {
        name: 'Nome',
        description: 'Descrição',
        beginDate: 'Início',
        area: 'Local',
        zoom: 'Zoom',
        hide: 'Escondida',
        noEquipments: 'Ainda não há equipamentos associados à campanha!',
        owner: 'Proprietário'
      },
      tabs: {
        details: "Detalhes",
        launches: "Lançamentos",
        datasets: "Datasets",
        equipment: "Equipamentos",
        teams: "Equipas",
        overlays: "Overlays",
        notes: "Notas",
        satellites: 'Satélites'
      },
      satellites: {
        calculate_button : 'Calcular',
        charts_color : 'Cor dos gráficos',
        begin_date: 'Data de início',
        end_date: 'Data de fim',
        color0: 'Azul',
        color1: 'Cinzento',
        color2: 'Laranja',
        chart1_xAxis_title: 'Horas p/Dia',
        chart1_yAxis_avereage_title : 'Média',
        chart1_yAxis_probability_title : 'Cobertura',
        chart1_avereageSeries_name: 'média satélites',
        chart1_probabilitySeries_name : 'cobertura',
        chart2_title : 'Total Satélites p/Período',
        minimum_elevation: 'Elevação mínima',
        request_error: 'Ocurreu um erro com o pedido de satélites'
      }
    },
    datasets: {
      name: 'Dataset |||| Datasets',
      fields: {
        name: 'Nome',
        equipmentId: 'Equipamento',
        launchId: 'Lançamento',
        campaignId: 'Campanha',
        numberObservations: 'Número de observações'
      },
      copyNotification: 'DerivedSet será processado em breve',
      copyButton: 'Clone',
      createDerivedset: 'Cria um DerivedSet',
      buildJob: 'Pretende atualizar o %{datasetName}?',
      buildJobWarning: 'O dataset atual irá ser apagado!',
      buildJobNotification: '%{datasetName} vai ser atualizado em breve',
      download_error: 'Ficheiro não encontrado'
    },
    equipment: {
      name: 'Equipamento |||| Equipamentos',
      allObservations: 'Todas as observações',
      fields: {
        state: "Estado",
        name: 'Nome',
        serialNumber: 'Número de Série',
        number: 'Número',
        equipmentModelId: 'Modelo',
        import: 'Importado',
        identifier: 'Argos Id',
        properties: {
          color: 'Cor'
        }
      },
      removed_eq: 'Os equipamentos selecionados foram removidos',
      dataset_published: 'Os equipamentos selecionados têm datasets publicados',
      recovery:{
        button_label: 'Modo Recuperação',
        confirm_button_label_activated: 'Modo de recuperação está ON',
        confirm_button_label_deactivated: 'Modo de recuperação está OFF',
        confirm_text_active: 'Tem a certeza de que quer ativar o mode de recuperação para este equipamento?',
        confirm_text_deactivate: 'Tem a certeza de que quer desativar o mode de recuperação para este equipamento?',
        success_notification: 'O pedido de recuperação foi enviado com sucesso',
        failure_notification: 'Erro a obter o modo da WAVY Ocean'
        // message_notification: 'Recovery message has already been sent'
      },
      agreement:{ 
        success: 'Termos de Serviço aceites',
        failure: 'Ocorreu um erro ao aceitar os Termos de Serviço',
        agreement: 'Termos de Serviço',
        to_accept: 'Aceitar os ',
        accepted: ' aceites',
      },
      tabs: {
        details: 'Detalhes',
        datasets: 'Datasets',
        campaigns: 'Campanhas',
        recovery: 'Modo Recuperação',
        calibrations: 'Calibrações'
      },
      calibrations: {
        calibrationDate: 'Data Calibração',
        validDate: 'Data Validade',
        sensorType: 'Nome sensor',
        onEquipment: 'No Equipamento',
        numVariables: 'Polinómio',
        polynomialDegree1: 'Linear',
        polynomialDegree2: 'Cúbico',
        diffValidationDate: 'Data Validade Diferente',
        sensor_1: 'temp_1',
        sensor_2: 'temp_2',
        none: 'Nenhum',
        filter: 'Filtro',
        formula: 'Valor do Sensor',
      }
    },
    institutions: {
      name: 'Instituição |||| Instituições',
      fields: {
        name: 'Nome',
        address: 'Endereço',
        phoneNumber: 'Contacto',
        acronym: "Acrónimo",
        url: "Sítio internet"
      },
      tabs: {
        details: "Detalhes",
        users: "Membros"
      }
    },
    observations: {
      name: 'Observação |||| Observações',
      fields: {
        number: 'Número',
        timestamp: 'Hora UTC',
        'position.lat': 'Latitude',
        'position.lng': 'Longitude',
        battery: 'Bateria',
        speed: 'Velocidade',
        direction: 'Direção',
        copying: 'A copiar'
      },
      button: {
        csv_export: 'CSV',
        metadata_export: 'Metadados'
      }
    },
    teams: {
      name: 'Equipa |||| Equipas',
      fields: {
        name: 'Nome',
        code: 'Código',
        description: "Descrição"
      },
      tabs: {
        details: 'Detalhes',
        users: 'Membros'
      }
    },
    users: {
      name: 'Utilizador |||| Utilizadores',
      fields: {
        username: 'Nome de Utilizador',
        name: 'Nome',
        email: 'E-Mail',
        institutionId: 'Instituição',
        active: 'Ativo',
        isAdmin: 'Administrador',
        role: 'Administrador'
      }
    },
    annotations: {
      name: 'Anotação |||| Anotações',
      fields: {
        observationIds: 'Ids das observações',
        title: 'Título',
        text: 'Texto',
        tagIds: 'Etiquetas'
      },
      messages: {
        updateError: 'Ocorreu um erro ao atualizar a anotação',
        dateGt: 'A data precisa de ser maior que %{date}',
        dateLt: 'A data precisa de ser menor que %{date}',
        getDatesError: 'Ocorreu um erro ao obter as datas limite',
        getAnnotations: 'Ocorreu um erro a obter as anotações',
        createAnnotations: 'Ocorreu um erro ao criar a anotação',
      },
    },
    notes: {
      name: 'Nota |||| Notas',
      title: 'Notas',
      fields:{
        beginDate: 'Data',
        description: 'Descrição',
        tagIds: 'Tags',
        campaign: 'Campanha'
      },
      campaignGetError: 'Erro a obter informação de campanhas',
      success: 'Nota criada',
      tabs: {
        details: 'Detalhes',
        description: 'Descrição',
      }
    },
    launches: {
      name: 'Lançamento |||| Lançamentos',
      fields: {
        state: "Estado",
        name: 'Nome',
        description: 'Descrição',
        beginTimestamp: 'Data de Início',
        endTimestamp: 'Data de Fim',
        autoPublish: 'Publicação automática',
        equipmentAdded: 'Equipamentos'
      },
      message: {
        build: 'Build completo'
      },
      buttons: {
        create: 'Criar',
        terminate: 'Terminar Lançamento',
        terminateAll: 'Terminar Todos',
        build: 'Build iniciado'
      },
      tabs: {
        details: 'Detalhes',
        equipment: 'Equipamentos'
      }
    },
    overlays: {
      name: 'Overlay |||| Overlays',
      fields: {
        name: 'Nome',
        filePath: 'Url',
        type: 'Tipo',
        opacity: 'Opacidade',
        layers: 'Camadas',
        image: 'Imagem',
        wms: 'WMS',
        kml: 'KML',
        southWest: 'Ponto Sudoeste',
        northEast: 'Ponto Nordeste'
      },
      file: 'Ficheiro',
      url: 'url'
    },
    files: {
      name: 'Ficheiro |||| Ficheiros',
      fields: {
        name: 'Nome',
        originalFilename: 'Nome do ficheiro original',
        identifier: 'Identificador',
        createdAt: 'Data de envio',
        number: 'Número',
        altitude: 'Altitude',
        speed: 'Velocidade',
        position: 'Posição',
        direction: 'Direção',
        satellites: 'Satélites',
        hdop: 'Hddp',
        timestamp: 'Hora',
        file:'Ficheiro',
        uploadFile: 'Carregar Ficheiro'
      },
      filter: {
        success: 'Sucesso',
        duplicated: 'Duplicado',
        pending: 'Pendente',
        error1: 'Erro',
        error2: 'Erro no número de colunas',
        error3: 'Erro com datas',
      }
    },
    derivedsets: {
      name: 'Derivado |||| Derivados',
      fields: {
        name: 'Nome',
        description: 'Descrição',
        removed_noti: 'Foram apagados %{id} elementos',
        remove: 'Apagar',
        numberObservations: 'Número de observações'
      },
      notification:{
        update_metadata: 'The metadata and CSV has been updated'
      },
      forms: {
        buttons: {
          tags_filter: 'Filtrar por tag',
          delete_observations: "Apagar conteúdo",
          delete_observations_confirm_title: "Apagar conteúdo",
          delete_observations_confirm_description: "Apagar todas as observações deste derivedSet?",
          delete_observations_confirm_notification: "Todas as observações apagadas",
        }
      }
    },
    equipmenttypes: {
      name: 'Tipo |||| Tipos',
      fields: {
        name: 'Nome'
      }
    },
    equipmentmodels: {
      name: 'Modelo |||| Modelos',
      fields: {
        name: "Nome",
        equipmenttypeId: "Tipo de equipamento",
        observedProperty: 'Propriedades',
        properties: {
          visible: 'Visível',
          observedpropertyId: 'Propriedade observada',
          unitOfMeasurement: 'Unidade de Medida',
          index: 'Índice'
        }
      },
      warning: {
        removeProperty: 'Não é possível remover'
      },
      errors: {
        index_lt1: 'Índice deve ser um valor superior a 0.',
        duplicate_index: 'Não podem haver índices duplicados.',
        related_properties: 'Erro a gravar propriedades.',
      }
    },
    observedProperties: {
      name: 'Propriedade |||| Propriedades',
      fields: {
        name: 'Nome',
        description: 'Descrição'
      }
    },
    tags:{
      name: 'Tag |||| Tags',
      tabs: {
        details: 'Detalhes',
      },
      fields: {
        properties: {
          color: 'Cor',
        },
      }
    },
    parameters:{
      name: 'Parametro |||| Parametros',
      title: 'Parametros',
      titleCurated: '(dataset derivado)',
      tabs: {
        details: 'Detalhes',
      },
      fields: {
        properties: {
          color: 'Cor',
        },
      },
      equipment: 'Equipamento',
      derived: "Derivado",
      calculate: "Calcular",
      download: "Exportar",
      error: "Não foram selecionados dados suficientes. Por favor selecione mais dados.",
      fileError: "Ficheiro não encontrado.",
    }
  }
};
