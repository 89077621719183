import React, { Component } from 'react';

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  translate,
  showNotification
} from 'react-admin';

import {
  FileDownload as FileDownloadIcon,
} from '@material-ui/icons';

import {
  Tooltip,
  IconButton
} from '@material-ui/core';
import { refreshView } from 'ra-core';
import { S3_ENDPOINT } from '../../Configuration';


class DownloadFile extends Component {

  handleAction = () => {
    window.open(`${S3_ENDPOINT}${this.props.record.file}`, '_blank', 'noopener');
  }

  render() {
    const {record} = this.props;
    return record.file ? (
      <Tooltip title={'Download'}>
        <div>
          <IconButton onClick={this.handleAction}>
            <FileDownloadIcon />
          </IconButton>
        </div>
      </Tooltip>
    ) : null;
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification,
    refreshView
  })
);

export default enhance(DownloadFile);