import React from 'react';

import {
  BooleanField,
  Create,
  // DateField,
  Filter,
  Pagination,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SearchInput,
  Show,
  ShowButton,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  DeleteButton,
  // NumberField,
  TabbedShowLayout,
  TextField,
  TextInput,
  required,
  translate
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/Lens';
import { ColorInput, ColorField } from 'react-admin-color-input';

import {
  Edit,
  DefaultCardActions,
  InputAggregator,
  ReferenceManyManyField,
  ResponsiveDatagrid,
  ResponsiveList,
  DownloadButton,
  DateField
} from '../../components';
import { hexToRgb } from '../../utils/util';
import TabbedShowLayoutTabs from '../../utils/CustomTabbedShowLayoutTabs';
import WAVYOcean from './WAVYOcean';
import ArgosId from './ArgosId';
import { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from 'react-redux'

import ListDatasets from '../datasets/ListDatasets';

import CalibrationTollbar from '../calibrations/CalibrationsTollbar';
import CalibrationField from '../calibrations/CalibrationField';
import { API_URL, WAVY_OCEAN_MODELS } from '../../Configuration';

export const EquipmentIcon = Icon;

const listStyles = {
  tabs: {
    width: 0
  }
};

const EquipmentFilter = translate(({ translate, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
  </Filter>
));

export const EquipmentList = withStyles(listStyles)(({ classes, ...props }) => {
  return <ResponsiveList
    {...props}
    listFilters={<EquipmentFilter />}
    recordActions={[<ShowButton />]}
    rowClick="show"
  >
    <TextField source="name" />
    <TextField source="serialNumber" />
    <TextField source="number" />
    <BooleanField source="import" />
    <ReferenceField source="equipmentmodelId" reference='equipmentmodels' linkType={false} >
      <TextField source="name" />
    </ReferenceField>
  </ResponsiveList>
});

const DownloadFileButton = ({ record, ...props }) => (
  record.filePath && <DownloadButton filePath={`${API_URL}/containers/${record.filePath}`} fileName={`${record.fileName}`} label='Download' />
);

class _EquipmentShow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: undefined,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (property, value) => {
    let _value = undefined;

    switch (value) {
      case 1:
        _value = 'temp_1';
        break;
      case 2:
        _value = 'temp_2';
        break;
      default:
        break;
    }
    this.setState({ filter: _value });
  };

  render() {
    const { classes, dispatch, equipment, ...props } = this.props;
    return (
      <Show actions={<DefaultCardActions {...props} hasCreate={false} title={equipment && equipment.name}/>} {...props} >
        <TabbedShowLayout tabs={<TabbedShowLayoutTabs classes={{ scrollable: classes.tabs }} scrollable scrollButtons={'on'} />}>
          <Tab label='resources.equipment.tabs.details'>
            <SimpleShowLayout>
              <TextField source="name" addLabel={true} />

              {equipment && WAVY_OCEAN_MODELS.includes(equipment.equipmentmodelId) && <WAVYOcean source="recovery" {...props} /> }

              <TextField source="serialNumber" />
              <TextField source="number" />
              <BooleanField source="import" />
              <ReferenceField source="equipmentmodelId" reference='equipmentmodels' linkType={false} >
                <TextField source="name" />
              </ReferenceField>
              <ColorField source="properties.color" />
            </SimpleShowLayout>
          </Tab>
          <Tab label='resources.equipment.tabs.datasets' path='datasets'>
            <ReferenceManyField reference='datasets' target='equipmentId' addLabel={false} pagination={<Pagination />}>
              <ListDatasets equipmentC={false} />
            </ReferenceManyField>
          </Tab>
          <Tab label='resources.equipment.tabs.campaigns' path='campaigns'>
            <ReferenceManyManyField source='equipment' reference='campaigns' addLabel={false} pagination={<Pagination />}>
              <ResponsiveDatagrid actions={[<ShowButton />]} >
                <TextField source='name' />
                <TextField source='description' />
                <DateField source="beginDate" format='dd/MM/yyyy' noFields />
                <BooleanField source="hide" />
              </ResponsiveDatagrid>
            </ReferenceManyManyField>
          </Tab>
          {equipment && WAVY_OCEAN_MODELS.includes(equipment.equipmentmodelId) && <Tab label='resources.equipment.tabs.calibrations' path='calibrations'>
            <CalibrationTollbar handleChange={this.handleChange} filter={this.state.filter} />
            <ReferenceManyField target='equipmentId' filter={{ sensorType: this.state.filter }} sort={{ field: 'calibrationDate', order: 'ASC' }} reference='calibrations' addLabel={false} pagination={<Pagination />}>
              <ResponsiveDatagrid actions={[<DeleteButton undoable={false} redirect={false} />, <DownloadFileButton />]} >
                <DateField label="resources.equipment.calibrations.calibrationDate" source="calibrationDate" format='dd/MM/yyyy HH:mm:ss' />
                <DateField label="resources.equipment.calibrations.validDate" source="validDate" format='dd/MM/yyyy HH:mm:ss' />

                <TextField label="resources.equipment.calibrations.sensorType" source='sensorType' />
                <BooleanField label="resources.equipment.calibrations.onEquipment" source="onEquipment" />
                <CalibrationField source='a0' />
                <CalibrationField source='a1' />
                <CalibrationField source='a2' />
                <CalibrationField source='a3' />
              </ResponsiveDatagrid>
            </ReferenceManyField>
          </Tab>}
        </TabbedShowLayout>
      </Show>
    )
  }

}



const mapStateToPropsShow = (state, props) => {
  return { equipment: state.admin.resources && state.admin.resources.equipment && state.admin.resources.equipment.data && props.id && state.admin.resources.equipment.data[props.id] };
}

const enhanceShow = compose(
  withStyles(listStyles),
  connect(mapStateToPropsShow)
);

export const EquipmentShow = enhanceShow(_EquipmentShow);

class _EquipmentEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipmentModel: undefined,
      loaded: false
    };
  }

  componentDidUpdate() {
    if (this.props.equipment && !this.state.loaded) {
      let equipmentModel = undefined;
      if (this.props.equipment[this.props.id]) {
        equipmentModel = this.props.equipment[this.props.id].equipmentmodelId;
        this.setState({ loaded: true, equipmentModel: equipmentModel });
      }
    }
  }

  render() {
    const { dispatch, ...rest } = this.props;
    return (
      <Edit {...rest}>
        <SimpleForm>
          <TextInput
            source="name"
            validate={required()}
          />
          <TextInput
            source="serialNumber"
            validate={required()}
          />
          <TextInput
            source="number"
            validate={required()}
          />

          <ReferenceInput onChange={(_, id) => this.setState({ equipmentModel: id })} source="equipmentmodelId" reference="equipmentmodels" validate={required()}>
            <SelectInput optionText="name" />
          </ReferenceInput>

          {this.state.equipmentModel === 4 && <ArgosId source="identifier" {...rest} />}

          <InputAggregator source="properties">
            <ColorInput source="color" parse={hexToRgb} />
          </InputAggregator>
        </SimpleForm>
      </Edit>
    )
  }
};

const mapStateToProps = state => {
  return { equipment: state.admin.resources && state.admin.resources.equipment && state.admin.resources.equipment.data };
}

const enhance = compose(
  connect(mapStateToProps)
)

export const EquipmentEdit = enhance(_EquipmentEdit);

export class EquipmentCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipmentModel: undefined
    };
  }

  render() {
    return (
      <Create actions={<DefaultCardActions />} {...this.props}>
        <SimpleForm redirect='show'>
          <TextInput
            source="name"
            validate={required()}
          />
          <TextInput
            source="serialNumber"
            validate={required()}
          />
          <TextInput
            source="number"
            validate={required()}
          />
          <ReferenceInput onChange={(_, id) => this.setState({ equipmentModel: id })} source="equipmentmodelId" reference="equipmentmodels" validate={required()}>
            <SelectInput optionText="name" />
          </ReferenceInput>

          {this.state.equipmentModel === 4 && <ArgosId {...this.props} />}

          <InputAggregator source="properties">
            <ColorInput source="color" parse={hexToRgb} />
          </InputAggregator>
        </SimpleForm>
      </Create>
    )
  }
};
