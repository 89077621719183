import 'babel-polyfill';
import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';
import {
  authProvider,
  dataProvider,
  i18nProvider
} from './providers';
import customRoutes from './routes';
import Layout from './Layout';
import Dashboard from './containers/dashboard';
import Login from './Login';
import './App.css';
import { AUTH_PROVIDER } from './Configuration';
import * as Resources from './resources';
import { storage } from './providers';

// reducers
import themeReducer from './containers/configuration/reducers';
import liveReducer from './containers/live/reducers';
import {
  fetchedObservations,
  overlays,
  selectedCampaign
} from './containers/history/reducers';
import manualReducer from './containers/manual/reducer';
import annotationsReducer from './containers/derivedsets/reducers';
import relatedPropertiesReducer from './resources/equipment-models/reducers';

// sagas
import * as sagas from './sagas';


class App extends Component {

  getLocale(){
    const userStorage = storage.load('lbtoken');
    const { user } = userStorage;
    return (user && user.settings) ? userStorage.user.settings.language : 'en-GB';
  }


  render() {
    return (
      // <Provider
      //   store={createStore({
      //     authProvider: authProvider(AUTH_PROVIDER),
      //     dataProvider: dataProvider,
      //     i18nProvider: i18nProvider,
      //     history
      //   })}
      // >
      <Admin
        title="WAVY Operation Software"
        dataProvider={dataProvider}
        customReducers={{
          theme: themeReducer,
          liveData: liveReducer,
          fetchedObservations,
          overlays,
          manual: manualReducer,
          relatedproperties: relatedPropertiesReducer,
          selectedCampaign,
          annotations: annotationsReducer
        }}
        customRoutes={customRoutes}
        customSagas={[sagas.watchLoginSuccess/*, manualSaga*/]}
        dashboard={Dashboard}
        loginPage={Login}
        appLayout={Layout}
        locale={this.getLocale()}
        i18nProvider={i18nProvider}
        authProvider={authProvider(AUTH_PROVIDER)}
      >

        {/* <Modal /> */}
        <Resource
          name="institutions"
          list={Resources.InstitutionList}
          show={Resources.InstitutionShow}
          create={Resources.InstitutionCreate}
          edit={Resources.InstitutionEdit}
        />

        <Resource
          name="users"
          list={Resources.UserList}
          show={Resources.UserShow}
          create={Resources.UserCreate}
          edit={Resources.UserEdit}
        />

        <Resource
          name="campaigns"
          list={Resources.CampaignList}
          show={Resources.CampaignShow}
          create={Resources.CampaignCreate}
          edit={Resources.CampaignEdit}
        />

        <Resource
          name="launches"
          create={Resources.LaunchesCreate}
          edit={Resources.LaunchesEdit}
          show={Resources.LaunchesShow}
        />

        {/* <Resource
          name="teams"
          list={Resources.TeamList}
          show={Resources.TeamShow}
          create={Resources.TeamCreate}
          edit={Resources.TeamEdit}
        /> */}

        <Resource
          name="datasets"
          list={Resources.DatasetList}
        />

        <Resource
          name="equipment"
          list={Resources.EquipmentList}
          show={Resources.EquipmentShow}
          create={Resources.EquipmentCreate}
          edit={Resources.EquipmentEdit}
        />

        <Resource
          name="calibrations"
          create={Resources.CalibrationsCreate}
          show={Resources.CalibrationsShow}
        />

        <Resource
          name='equipmentmodels'
          list={Resources.EquipmentModelList}
          create={Resources.EquipmentModelCreate}
          edit={Resources.EquipmentModelEdit}
          show={Resources.EquipmentModelShow}
        />

        <Resource
          name='equipmenttypes'
          list={Resources.EquipmentTypeList}
          create={Resources.EquipmentTypeCreate}
          edit={Resources.EquipmentTypeEdit}
          show={Resources.EquipmentTypeShow}
        />

        <Resource
          name="observations"
          list={Resources.ObservationList}
          show={Resources.ObservationShow}
        />
        <Resource
          name='annotations'
        />
        <Resource
          name='overlays'
          list={Resources.OverlaysList}
          create={Resources.OverlaysCreate}
          show={Resources.OverlaysShow}
          edit={Resources.OverlayEdit}
        />
        <Resource
          name='tags'
          list={Resources.TagList}
          create={Resources.TagCreate}
          edit={Resources.TagEdit}
          show={Resources.TagShow}
        />
        <Resource
          name='files'
          list={Resources.FilesList}
          show={Resources.FilesShow}
          create={Resources.FileCreate}
        />
        <Resource
          name='manuals'
        />
        <Resource
          name='derivedsets'
          list={Resources.DerivedDtsList}
          create={Resources.DerivedDtsCreate}
          edit={Resources.DerivedDtsEdit}
          show={Resources.DerivedDtsShow}
        />
        <Resource
          name='observedProperties'
          list={Resources.ObservedPropertiesList}
          create={Resources.ObservedPropertiesCreate}
          edit={Resources.ObservedPropertiesEdit}
          show={Resources.ObservedPropertiesShow}
        />
        <Resource
          name="modelproperties"
        />
        {/* Counts using only one request, ref#127 */}
        <Resource
          name="datasets/count/observations"
        />
        <Resource
          name="users/public"
        />
        <Resource
          name="launches/count/equipment"
        />
        <Resource
          name="publishes"
        />
        <Resource
          name="notes"
          edit={Resources.NotesEdit}
          show={Resources.NoteShow}

        />
      </Admin>
      // </Provider>
    );
  }
}

export default App;
