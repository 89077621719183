import React from 'react';
import {
  ReferenceField,
  ReferenceFieldController,
  TextField
} from 'react-admin';
// import PublishButton from './PublishButton';
import {
  List as Icon,
} from '@material-ui/icons';
import {
  ShowRelatedButton,
  ResponsiveDatagrid
} from '../../components';
import Status from './Status'

export const DatasetIcon = Icon;

const tableVisible = (record) => record && record.status === 'UPDATED';

const ListDatasets = ({ equipmentC, campaignC, ...rest }) => (
  <ResponsiveDatagrid actions={[<ShowRelatedButton isVisible={tableVisible} source_="datasetId" reference='observations' source='datasetId' label='pos.general.table' icon={<DatasetIcon />} />/*, <PublishButton basePath="datasets"/>*/]} {...rest}>
    <TextField source="name" />

    {equipmentC &&
      <ReferenceField source="equipmentId" reference="equipment" linkType="show" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
    }
    <ReferenceField reference="launches" source="launchId" linkType={false} allowEmpty>
      <TextField source="name" />
    </ReferenceField>

    {campaignC &&
      <ReferenceFieldController label='resources.datasets.fields.campaignId' reference="launches" source="launchId" linkType={false} sortable={false} >
        {({ referenceRecord }) => (
          <ReferenceField basePath="/launches" resource="launches" reference="campaigns" source="campaignId" record={referenceRecord || {}} linkType="show" allowEmpty>
            <TextField source="name" sortable={false} />
          </ReferenceField>
        )}
      </ReferenceFieldController>
    }
    {/* <ReferenceField label="resources.datasets.fields.observationsNumb" source="id" reference="datasets/count/observations" linkType={false} sortable={false} allowEmpty>
      <TextField source="count" />
    </ReferenceField> */}
    <TextField source="numberObservations" />

    <Status source="status" />

  </ResponsiveDatagrid>
);


ListDatasets.defaultProps = {
  equipmentC: true,
  campaignC: true
};

export default ListDatasets;
