import React, { Component } from 'react';
import { Card, withStyles, Typography, CardActions } from '@material-ui/core'
import {
  Button,
  Title,
  showNotification,
  fetchEnd,
  fetchStart,
  translate
} from 'react-admin';
import compose from 'recompose/compose';
import {Link} from 'react-router-dom';
import {
  List as DatasetIcon,
  Map as MapIcon,
  ViewList as TableIcon,
} from '@material-ui/icons';
import { stringify } from 'query-string';
import { connect } from 'react-redux';

import dataFetch from '../../providers/dataFetch';
import { ReactChart } from '../../components';

const styles = {
  actions:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  card:{
    display: 'flex',
    height: '100%',
    paddingTop: '2em',
    paddingBottom: '2em',
    paddingRight: '1em',
    paddingLeft: '1em',
    flexDirection: 'column'
  },
  flex: { display: 'flex', marginTop: '0em' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '1em' },
  singleCol: { marginTop: '2em', marginBottom: '2em' },
  welcome: { width: '100%' }
};


class ChartsContainer extends Component {
  constructor(props) {
    super(props);
    this.rerenderParentCallback = this.rerenderParentCallback.bind(this);

  }

  getAnnotations = (datasetId) => {
    const filter = {
      where: {datasetId: datasetId},
      include: ['tags',"intervals"]
    };

    return new Promise((resolve, reject) => {
      dataFetch('GET', `/annotations?filter=${JSON.stringify(filter)}`)
        .then(data => resolve(data))
        .catch(err => reject(err));
    });
  };
  
  rerenderParentCallback() {
    const { match, fetchEnd, fetchStart } = this.props;

    const promises = [this.getAnnotations(match.params.id)];
      
    fetchStart();
    Promise.all(promises).then((values) => {

      let annotations = values[0];

      this.setState({ annotationsArray: annotations, });

    })
    .catch(_ => this.props.showNotification('ra.navigation.no_results'))
    .finally(_ => fetchEnd());
    this.forceUpdate();
  }
  state = {
    name: null,
    legend: [],
    chartData: [],
    annotationsArray: [],
    allowToAddAnnotations: undefined,
  };

  componentDidMount() {
    const { match, fetchEnd, fetchStart } = this.props;

    // const getDataset = (datasetId) => {
    //   return new Promise((resolve, reject) => {
    //     dataFetch('GET', `/datasets/${datasetId}?filter=${JSON.stringify({include: 'equipment'})}`)
    //       .then(dataset => {
    //         if (dataset && !dataset.error) {
    //           return resolve(dataset);
    //         } else {
    //           return reject();
    //         }
    //       })
    //       .catch(err => reject(err));
    //   });
    // }

    // const getModelProperties = (datasetId) => {
    //   return new Promise((resolve, reject) => {
    //     getDataset(datasetId)
    //       .then(dataset => {
    //         this.setState({name: dataset.name});
    //         const { equipmentmodelId } = dataset.equipment;
    //         const filter = {
    //           where: {equipmentmodelId: equipmentmodelId},
    //           include: 'observedproperty'
    //         };
    //         return dataFetch('GET', `/modelproperties?filter=${JSON.stringify(filter)}`);
    //       })
    //       .then(modelproperties => resolve(modelproperties))
    //       .catch(err => reject(err));
    //   });
    // };

    const getObservations = (datasetId) => {
      const filter = {
        where: {datasetId: datasetId},
        order: ["timestamp ASC"]
      };

      return new Promise((resolve, reject) => {
        dataFetch('GET', `/observations?filter=${JSON.stringify(filter)}`)
          .then(data => resolve(data))
          .catch(err => reject(err));
      });
    };


    const promises = [
      // getModelProperties(match.params.id),
      getObservations(match.params.id),
      this.getAnnotations(match.params.id)
    ];
      
    fetchStart();
    Promise.all(promises).then((values) => {
      // const modelProperties = values[0];
      let observations = values[0];
      let annotations = values[1];
      // let legend = modelProperties.filter(p => p.visible).map(p => p.observedproperty.name);
      // legend.unshift('timestamp');

      const invisible_props = [
        "datasetId", 
        "id", 
        "position", 
        "record", 
        "references", 
        "timestamp",
        "serialNumber",
        "gpstime",
        "gpsdatetime",
        "properties",
        "day",
        "hour",
        "latitude",
        "longitude",
        "minute",
        "month",
        "second",
        "year",
        "sampleimu",
        "samplepressure",
        "sampletemperature",
        "file",
        "version"
      ];

      let legend = Object.keys(observations[0]).filter(obs => !invisible_props.includes(obs));
      legend.unshift('timestamp');

      // observations.forEach(({timestamp, temp_1}) => {
      //   if(temp_1 > 60)
      //     console.log(timestamp, temp_1)
      // })
      // console.log(legend)
      observations.forEach(observation => {
        observation.timestamp = new Date(observation.timestamp)
        Object.keys(observation).forEach(key => {
          if(!legend.includes(key))
            delete observation[key];
        });
      });

      this.setState({ 
        chartData: observations, 
        legend: legend,
        annotationsArray: annotations, 
      });

    })
    .catch(_ => this.props.showNotification('ra.navigation.no_results'))
    .finally(_ => fetchEnd());
  }
  setAnnotationsOnOff = value => {
    this.setState({ allowToAddAnnotations: value });
    return value;
  };
  render() {
    const { classes, translate } = this.props;
    const { chartData, legend, name, annotationsArray, allowToAddAnnotations } = this.state;
    const datasetId = parseInt(this.props.match.params.id);
    const annotationsEnabled = false;
    return (
      <Card classes={{root:classes.card}} >
        <Title title={translate('containers.charts.name')} />
        <CardActions classes={{root:classes.actions}}>
        <Typography style={{alignSelf:'flex-start'}} variant='title' >{name}</Typography>

          <div style={styles.flex}>
                <div style={styles.leftCol}>
                  <div style={styles.flex}>
                    <Button style={{marginRight:'8px'}} component={Link} label='pos.general.map' to={`/datasets/${datasetId}/map`}><MapIcon/></Button>
                    <Button style={{marginRight:'8px'}} component={Link}
                      label='pos.general.table'
                      to={{
                        pathname: '/observations',
                        search: stringify({
                          page: 1,
                          perPage: 25,
                          filter: JSON.stringify({ datasetId: datasetId }),
                        })
                      }}><TableIcon/></Button>
                    <Button component={Link} label={translate('resources.datasets.name',{smart_count:2})} to={'/datasets'}><DatasetIcon/></Button>                  
                    </div>
                  </div>
              </div>
        </CardActions>
        <ReactChart data={chartData}  recordId={datasetId} properties={legend} annotationsEnabled={annotationsEnabled}
        annotations={annotationsArray} rerenderParentCallback={this.rerenderParentCallback} allowToAddAnnotations={allowToAddAnnotations}/>
      </Card>
    )
  }
};

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, {
    showNotification,
    fetchEnd,
    fetchStart
  })
)

export default enhance(ChartsContainer);