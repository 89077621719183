import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, withStyles } from "@material-ui/core";
import {
  DateField,
  Pagination,
  ReferenceInput,
  SelectInput,
  TextField,
  Title,
  fetchEnd,
  fetchStart,
  required,
  translate,
} from "react-admin";

import moment from "moment";
import compose from "recompose/compose";
import { storage } from "react-admin-loopback";

import SearchObsSimpleForm from "./CustomSimpleForm";
import { dataFetch } from "../../providers";
import { ResponsiveDatagrid, DateTimeInput } from "../../components";
import SearchObsToolbar from "./SearchObsToolbar";

import { API_URL } from "../../Configuration";

const styles = {
  form: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
  },
  formContent: {
    display: "flex",
    flexDirection: "row",
  },
  inputMargin: {
    marginRight: "16px",
  },
};

class SearchObservations extends Component {
  state = {
    data: {},
    ids: [],
    total: 0,
    observedProperties: [],
    page: 1,
    perPage: 25,
    sort: {
      field: "timestamp",
      order: "DESC",
    },
    beginTimestamp: null,
    endTimestamp: null,
    version: 0,
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.beginTimestamp &&
      state.beginTimestamp &&
      props.beginTimestamp.getTime() !== state.beginTimestamp.getTime()
    ) {
      state.beginTimestamp = props.beginTimestamp;
      return state;
    }
    if (
      props.endTimestamp &&
      state.endTimestamp &&
      props.endTimestamp.getTime() !== state.endTimestamp.getTime()
    ) {
      state.endTimestamp = props.endTimestamp;
      return state;
    }

    return null;
  }

  getEquipmentProperties = (id) => {
    return new Promise((resolve, reject) => {
      dataFetch(
        "GET",
        `/equipment/${id}?filter=${JSON.stringify({ include: "datasets" })}`
      )
        .then((equipment) => {
          if (equipment && equipment.equipmentmodelId) {
            const filter = {
              where: { equipmentmodelId: equipment.equipmentmodelId },
              sort: { field: "index", order: "ASC" },
              include: "observedproperty",
            };

            dataFetch(
              "GET",
              `/modelproperties?filter=${JSON.stringify(filter)}`
            )
              .then((properties) => {
                const observedProperties = properties.filter((element) => {
                  return element.visible;
                });

                resolve({
                  equipment: equipment,
                  observedProperties: observedProperties,
                });
              })
              .catch((err) => reject(err));
          } else {
            reject();
          }
        })
        .catch((err) => reject(err));
    });
  };

  save = (record) => {
    const page = 1;
    const sort = {
      field: "timestamp",
      order: "DESC",
    };

    this.setState({
      // beginTimestamp: record.beginTimestamp,
      // endTimestamp: record.endTimestamp,
      page,
      sort,
      data: {},
    });

    this.getEquipmentProperties(record.equipmentId)
      .then((data) => {
        const { equipment, observedProperties } = data;
        const datasetIds = equipment.datasets.map((dt) => dt.id);
        const { perPage } = this.state;

        const filter = {
          where: {
            and: [
              { timestamp: { gt: record.beginTimestamp } },
              { timestamp: { lt: record.endTimestamp } },
              { datasetId: { inq: datasetIds } },
            ],
          },
          limit: perPage,
          offset: (page - 1) * perPage,
          order: [`${sort.field} ${sort.order}`],
        };

        this.setState({ observedProperties, datasetIds, filter: filter.where });
        this.fetchRecords(filter);
      })
      .finally((_) => {
        this.setState({ fetchObsProps: false });
      });
  };

  fetchRecords = (filter) => {
    const { fetchEnd, fetchStart } = this.props;

    fetchStart();
    const headers = new Headers();
    headers.append("Authorization", `${storage.load("lbtoken").id}`);

    fetch(`${API_URL}/observations?filter=${JSON.stringify(filter)}`, {
      method: "GET",
      headers,
    })
      .then((resp) => {
        const contentRange = resp.headers.get("Content-range");
        const total = contentRange.split("/")[1];
        this.setState({ total: parseInt(total) });
        return resp.json();
      })
      .then((observations) => {
        const data = observations.reduce((acc, row, index) => {
          acc[index] = Object.assign({}, row, { id: index });
          return acc;
        }, {});
        this.setState({ data, ids: Object.keys(data) });
        this.setState((state) => ({ version: state.version + 1 }));

        fetchEnd();
      });
  };

  setPage = (page) => {
    const { beginTimestamp, endTimestamp, datasetIds, sort, perPage } =
      this.state;
    const filter = {
      where: {
        and: [
          { timestamp: { gt: beginTimestamp } },
          { timestamp: { lt: endTimestamp } },
          { datasetId: { inq: datasetIds } },
        ],
      },
      limit: perPage,
      offset: (page - 1) * perPage,
      order: [`${sort.field} ${sort.order}`],
    };

    this.fetchRecords(filter);
    this.setState({ page });
  };

  setPerPage = (perPage) => {
    const { beginTimestamp, endTimestamp, datasetIds, sort, page } = this.state;
    const filter = {
      where: {
        and: [
          { timestamp: { gt: beginTimestamp } },
          { timestamp: { lt: endTimestamp } },
          { datasetId: { inq: datasetIds } },
        ],
      },
      limit: perPage,
      offset: (page - 1) * perPage,
      order: [`${sort.field} ${sort.order}`],
    };

    this.fetchRecords(filter);
    this.setState({ perPage });
  };

  validateFields = (values, { translate }) => {
    console.log("values", values);
    const errors = {};
    const beginTimestamp = moment(this.state.beginTimestamp);
    const endTimestamp = moment(this.state.endTimestamp);

    if (!this.state.beginTimestamp || !beginTimestamp.isValid()) {
      errors.beginTimestamp = [translate("ra.validation.required")];
    }
    if (!this.state.endTimestamp || !endTimestamp.isValid()) {
      errors.endTimestamp = [translate("ra.validation.required")];
    }

    console.log("beginTimestamp", beginTimestamp.isValid());
    console.log("endTimestamp", endTimestamp.isValid());

    if (
      this.state.beginTimestamp &&
      beginTimestamp.isValid() &&
      this.state.endTimestamp &&
      endTimestamp.isValid() &&
      !endTimestamp.isAfter(beginTimestamp)
    ) {
      console.log("ERROR", endTimestamp.isAfter(beginTimestamp));
      console.log("ERROR", endTimestamp.toString());
      console.log("ERROR", beginTimestamp.toString());
      console.log("ERROR", this.state.endTimestamp && endTimestamp.isValid());
      console.log("ERROR", this.state.beginTimestamp && beginTimestamp.isValid());
      errors.endTimestamp = [translate("error.END_TIMESTAMP_EARLIER")];
    }

    return errors;
  };

  render() {
    const { classes } = this.props;
    const {
      data,
      filter,
      ids,
      observedProperties,
      page,
      perPage,
      sort,
      total,
      version,
    } = this.state;
    return (
      <Card>
        <Title title="containers.search.name" />
        <SearchObsSimpleForm
          onChange={this.fieldChanged}
          formContentClassName={classes.formContent}
          className={classes.form}
          resource="observations"
          save={this.save}
          validate={this.validateFields}
          toolbar={
            <SearchObsToolbar
              filter={filter}
              sort={sort}
              data={data}
              resource="observations"
            />
          }
        >
          <ReferenceInput
            onChange={this.setDirty}
            style={{ marginRight: "16px" }}
            validate={required()}
            label="containers.search.fields.equipment"
            source="equipmentId"
            reference="equipment"
            perPage={-1}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <DateTimeInput
            validate={required()}
            source="beginTimestamp"
            label="containers.search.fields.beginTimestamp"
            className={classes.inputMargin}
            options={{
              format: "dd/MM/yyyy HH:mm",
              clearable: true,
            }}
            onChange={(_, newDate) =>
              this.setState({ beginTimestamp: new Date(newDate) })
            }
          />
          <DateTimeInput
            validate={required()}
            source="endTimestamp"
            label="containers.search.fields.endTimestamp"
            className={classes.inputMargin}
            options={{
              format: "dd/MM/yyyy HH:mm",
              clearable: true,
            }}
            onChange={(_, newDate) =>
              this.setState({ endTimestamp: new Date(newDate) })
            }
          />
        </SearchObsSimpleForm>
        <div style={{ marginLeft: "1em", marginRight: "1em" }}>
          <ResponsiveDatagrid
            version={version}
            currentSort={sort}
            data={data}
            ids={ids}
            resource={"observations"}
            setSort={() => {}}
          >
            <DateField source="timestamp" showTime sortable={false} />
            {observedProperties &&
              observedProperties.length > 0 &&
              observedProperties.map((property, index) => {
                return (
                  <TextField
                    key={index}
                    source={property.observedproperty.name}
                    sortable={false}
                  />
                );
              })}
          </ResponsiveDatagrid>
          <Pagination
            page={page}
            perPage={perPage}
            setPage={this.setPage}
            setPerPage={this.setPerPage}
            total={total}
          />
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    beginTimestamp: state.form["record-form"]
      ? state.form["record-form"].values.beginTimestamp
      : null,
    endTimestamp: state.form["record-form"]
      ? state.form["record-form"].values.endTimestamp
      : null,
  };
};

const enhance = compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps, { fetchEnd, fetchStart })
);

export default enhance(SearchObservations);
