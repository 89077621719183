import React from 'react';

import {
  Create,
  Filter,
  Pagination,
  SearchInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TabbedShowLayout,
  Tab,
  required,
  UrlField,
  ReferenceManyField,
  translate
} from 'react-admin';

import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/icons/LocationCity';
import { 
  DefaultCardActions,
  Edit,
  ResponsiveDatagrid,
  ResponsiveList
} from '../../components';

export const InstitutionIcon = Icon;

const listStyles = {};

const InstitutionFilter = translate(({translate, ...props}) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
  </Filter>
));

export const InstitutionList = withStyles(listStyles)(({ classes, ...props }) => (
  <ResponsiveList
    {...props}
    listFilters={<InstitutionFilter />}
    recordActions={[<ShowButton />]}
    rowClick="show"
  >
    <TextField source="name" />
    <TextField source="acronym" />
    <TextField source="address" />
    <UrlField source="url" />
    <TextField source="phoneNumber" />
  </ResponsiveList>
));

export const InstitutionShow = withStyles(listStyles)(({ classes, ...props }) => {
  return(
  <Show actions={<DefaultCardActions />} {...props}>
    <TabbedShowLayout>
      <Tab label='resources.institutions.tabs.details'>
        <SimpleShowLayout>
          <TextField source="name" />
          <TextField source="acronym" />
          <TextField source="address" />
          <UrlField source="url" />
          <TextField source="phoneNumber" />
        </SimpleShowLayout>
      </Tab>
      <Tab label='resources.institutions.tabs.users' path='users'>
        <ReferenceManyField reference='users' target='institutionId' addLabel={false} pagination={<Pagination/>}>
          <ResponsiveDatagrid>
              <TextField source="name" />
              <TextField source="email" />
          </ResponsiveDatagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)});

export const InstitutionEdit = withStyles(listStyles)(({ classes, ...props }) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        validate={required()}
      />
      <TextInput
        source="acronym"
      />
      <TextInput
        source="address"
      />
      <TextInput
        source="url"
        type="url"
      />
      <TextInput
        source="phoneNumber"
      />
    </SimpleForm>
  </Edit>
));

export const InstitutionCreate = withStyles(listStyles)(({ classes, ...props }) => (
  <Create actions={<DefaultCardActions/>} {...props}>
    <SimpleForm redirect='show'>
      <TextInput
        source="name"
        validate={required()}
      />
      <TextInput
        source="acronym"
      />
      <TextInput
        source="address"
      />
      <TextInput
        source="url"
        type="url"
      />
      <TextInput
        source="phoneNumber"
      />
    </SimpleForm>
  </Create>
));