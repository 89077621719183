import React from 'react';
import {
  Create,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  TextField,
  required
} from 'react-admin';

import {
  DefaultCardActions,
  Edit,
  ResponsiveList
} from '../../components';

export {default as EquipmentTypeIcon} from '@material-ui/icons/ChangeHistory'

export const EquipmentTypeList = ({permissions, ...props}) => (
  permissions === 'sysadmin'
    ? <ResponsiveList
        recordActions={[<ShowButton />]}
        rowClick="show"
      {...props}>
      <TextField source="name" />
      </ResponsiveList>
    : null
)

export const EquipmentTypeCreate = ({permissions, ...props}) => (
  permissions === 'sysadmin' ? <Create actions={<DefaultCardActions/>}  {...props}>
    <SimpleForm redirect='list'>
      <TextInput source='name' validate={required()} />
    </SimpleForm>
  </Create> : null
)

export const EquipmentTypeEdit = ({permissions, ...props}) => (
  permissions === 'sysadmin' ? <Edit actions={<DefaultCardActions {...props}/>} {...props}>
    <SimpleForm>
      <TextInput source='name' validate={required()} />
    </SimpleForm>
  </Edit> : null
)

export const EquipmentTypeShow = ({permissions, ...props}) => (
  permissions === 'sysadmin' ? <Show actions={<DefaultCardActions  {...props} hasCreate={false}/>} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
    </SimpleShowLayout>
  </Show> : null
)