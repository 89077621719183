import React, { Component } from 'react';
import {
  Filter,
  SearchInput,
  translate,
  showNotification,
} from 'react-admin';
import {
  List as Icon,
} from '@material-ui/icons';

import {
  ResponsiveL,
} from '../../components';

// import { SOCKET_DEBUG } from '../../Configuration';

// import { 
//   emit,
//   listen,
//   stopListen,
// } from '../../sockets'

import { compose } from 'recompose';
import { connect } from 'react-redux';

import ListDatasets from './ListDatasets';
// import { refreshView } from 'ra-core';

export const DatasetIcon = Icon;

const DatasetFilter = translate(({ translate, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
  </Filter>
));


class _DatasetList extends Component {
  // componentDidMount() {
  //   this.props.emit('enter', {room : 'datasetList'})
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err datasetList", err);
  //   });

  //   this.props.listen('datasetList', (data) => { // ! duplicaded
  //     const { refresh, dataset } = data;
  //     if(refresh && this.props.ids.includes(dataset.id)) {
  //       // this.props.showNotification('sockets.update_data');
  //       this.props.refreshView();
  //     }
  //   })
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err datasetList", err);
  //   });
  // }

  // componentWillUnmount() {
  //   this.props.stopListen('datasetList')
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err datasetList", err);
  //   });

  //   this.props.emit('leave', {room : 'datasetList'})
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err datasetList", err);
  //   });
  // }

  render() {
    const { classes, listen, stopListen, emit, showNotification, /*refreshView,*/ ...rest } = this.props;
    return (
      <ResponsiveL
        {...rest}
        listFilters={<DatasetFilter />}
      >
        <ListDatasets />
      </ResponsiveL >

    )
  }
}

const mapStateToProps = (state) => ({
  ids: state.admin.resources.datasets.list.ids
});

const enhance = compose(
  connect(mapStateToProps, {
    // listen,
    // stopListen,
    // emit,
    showNotification,
    // refreshView,
  })
);

export const DatasetList = enhance(_DatasetList);