import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  translate
} from 'react-admin';
import L from 'leaflet';
import moment from 'moment';
import TimelineControl from 'leaflet-timeline';
import LeafletMap from '../../components/map/LeafletMap';
import '../../plugins/leaflet-extra-markers-improved/js/leaflet.extra-markers.min.js';
import '../../plugins/leaflet-extra-markers-improved/css/leaflet.extra-markers.min.css';

class TimeControlledMap extends Component {
  state = {
    controlLayer: null,
    viewport: {}
  }

  componentDidMount() {
    const { center, zoom } = this.props;
    const viewport = { center: center, zoom: zoom };

    this.setState({ viewport: viewport })
  }

  render() {
    const { translate, geodata, legend, annotation = false, refreshData, recordId, openAnnotation } = this.props;
    const { controlLayer, viewport } = this.state;
    // const viewport = {center: center, zoom: zoom};

    const timelineControlOptions = {
      timelineOptions: {
        marker: (coordinates, properties) => {
          let marker = L.marker(coordinates, {
            icon: L.ExtraMarkers.icon({
              icon: properties.id.length > 4 ? 'fb-number' : 'fa-number',
              markerColor: 'blue',
              prefix: 'fa',
              number: `${properties.id.substr(0, 2)}\n${properties.id.substr(2, properties.id.length)}`,
            })
          });
          return marker;
        },
        markerPopup: function (properties) {
          let popup = `<h3>${properties.id}</h3>`;
          if (properties.timestamp) {
            popup += `<div>time: ${moment(properties.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')} UTC</div>`
          }
          const hiddenProperties = ['gpstime', 'timestamp', 'position', 'properties', 'serialNumber', 'id', 'key'];
          Object.keys(properties).map((key) => {
            // In order to present the property must have the "value" prop otherwise ignore
            if (!hiddenProperties.includes(key) && properties[key].value) {
              const prop = properties[key].value + (properties[key].unit ? ` ${properties[key].unit}` : '');
              const value = properties[key].value ? prop : translate('containers.properties.no_data');
              return popup += `<div>${key}: ${value}</div>`;
            }
            return null;
          });

          return popup;
        }
      },
      timecontrolOptions: {
        legend,
        showTicks: false,
        speeds: [1, 2, 4, 8, 16, 32, 64],
        speedsUnity: [{
            seconds: 1,
            text: '1s'
          },{
            seconds: 5,
            text: '5s'
          },{
            seconds: 10,
            text: '10s'
          },{
            seconds: 30,
            text: '30s'
          },{
            seconds: 60,
            text: '1m'
          },{
            seconds: 300,
            text: '5m'
          },{
            seconds: 600,
            text: '10m'
          },{
            seconds: 1800,
            text: '30m'
          },{
            seconds: 3600,
            text: '1h'
          },{
            seconds: 7200,
            text: '2h'
          }
        ],
        formatOutput: (date) => {
          return moment(date).utc().format('DD-MM-YYYY HH:mm:ss') + ' UTC';
        }
      }
    };
    return <LeafletMap viewport={viewport} sendCtrlLayer={(layer) => this.setState({ controlLayer: layer })} annotation={annotation} recordId={recordId} refreshData={refreshData}>
      {geodata && geodata.features.length > 0 && controlLayer && <TimelineControl geoJSON={geodata} multiLine={annotation} ctrlLayer={controlLayer} openAnnotation={openAnnotation} {...timelineControlOptions} />}
    </LeafletMap>
  }
}

TimeControlledMap.propTypes = {
  geodata: PropTypes.object,
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
}

TimeControlledMap.defaultProps = {
  center: [41.1619, -8.5835],
  zoom: 5,
};

export default translate(TimeControlledMap);