import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BooleanField,
  BooleanInput,
  Create,
  // DateField,
  EditButton,
  Filter,
  Pagination,
  SearchInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  required,
  translate,
  NullableBooleanInput,
  fetchStart,
  fetchEnd,
  showNotification,
  ListButton,
  crudDeleteMany as crudDeleteManyAction,
  AutocompleteInput,
  ReferenceInput,
  ReferenceField,
  Labeled,
} from "react-admin";

import { Map, Marker, TileLayer } from "react-leaflet";
import { Typography, withStyles, Button, IconButton } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { DirectionsBoat as BoatIcon, Delete } from "@material-ui/icons";
import compose from "recompose/compose";
import { change as changeAction } from "redux-form";
import TabbedShowLayoutTabs from "../../utils/CustomTabbedShowLayoutTabs";

import {
  DefaultCardActions,
  DissociateButton,
  GeoCoordinatesInput,
  GeoCoordinatesField,
  ReferenceManyManyField,
  Relation,
  RelationShow,
  ResponsiveDatagrid,
  ResponsiveList,
  DateInput,
  Edit,
  LocalSearch,
  DateField,
} from "../../components";

import LaunchesToolbar from "./LaunchesToolbar";
import LaunchOpenField from "./LaunchOpenField";
import EquipmentActiveField from "./EquipmentActiveField";
import MyRelation from "./RelationNotes";

import ListDatasets from "../datasets/ListDatasets";
import MapButton from "./historyMapButton";

import {
  CheckCircle as CheckCircleIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@material-ui/icons";

import SatelliteCover from "./Satellites";
export const CampaignIcon = BoatIcon;

const listStyles = function styles(theme) {
  const height = 32;
  let backgroundColor =
    theme.palette.type === "light"
      ? theme.palette.grey[300]
      : theme.palette.grey[700];
  // var deleteIconColor = (0, _colorManipulator.fade)(theme.palette.text.primary, 0.26);
  return {
    tag_root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      height: height,
      color: theme.palette.getContrastText(backgroundColor),
      backgroundColor: backgroundColor,
      borderRadius: height / 2,
      whiteSpace: "nowrap",
      transition: theme.transitions.create(["background-color", "box-shadow"]),
      // label will inherit this from root, then `clickable` class overrides this for both
      cursor: "default",
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: "none",
      textDecoration: "none",
      border: "none",
      // Remove `button` border
      padding: 0, // Remove `button` padding,
    },
    tag_label: {
      display: "flex",
      alignItems: "center",
      paddingLeft: 12,
      paddingRight: 12,
      userSelect: "none",
      whiteSpace: "nowrap",
      cursor: "inherit",
    },
    tabs: {
      width: 0,
    },
    flexible: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
    },
    detailsData: {
      flex: 0.6,
    },
    detailsMap: {
      [theme.breakpoints.down("xs")]: {
        minHeight: theme.spacing.unit * 40,
      },
      flex: 1,
      minHeight: theme.spacing.unit * 40,
      display: "flex",
      paddingTop: "16px",
      justifyContent: "flex-end",
    },
    fullWidth: {
      width: "100%",
    },
    right: {
      float: "right",
    },
    warningEquipments: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        // '@media (hover: none)': {
        //   backgroundColor: 'transparent',
        // },
      },
    },

    label: {
      // Move the text out of the flow of the container.
      position: "absolute",

      // Reduce its height and width to just one pixel.
      height: 1,
      width: 1,

      // Hide any overflowing elements or text.
      overflow: "hidden",

      // Clip the box to zero pixels.
      clip: "rect(0, 0, 0, 0)",

      // Text won't wrap to a second line.
      whiteSpace: "nowrap",
    },
  };
};

const CampaignFilter = translate(({ translate, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate("pos.search")} />
    <NullableBooleanInput source="hide" />
    <NullableBooleanInput source="public" />
  </Filter>
));

export const CampaignList = withStyles(listStyles)(({ classes, ...props }) => (
  <ResponsiveList
    {...props}
    listFilters={<CampaignFilter />}
    recordActions={[<ShowButton />]}
    rowClick="show"
  >
    <TextField source="name" />
    <TextField source="description" />
    <DateField source="beginDate" format="dd/MM/yyyy" noFields />
    <GeoCoordinatesField source="area" />
    <TextField source="zoom" />
    <BooleanField source="hide" />
    <BooleanField source="public" />
  </ResponsiveList>
));

const CampaignActions = ({ data, basePath, ...props }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        {data && <Typography variant="title">{data.name}</Typography>}
        <Typography variant="caption"> </Typography>
      </div>
      <div style={{ right: 0 }}>
        {data && <EditButton record={{ id: data.id }} basePath={basePath} />}
        {data && <ListButton basePath={basePath} />}
      </div>
    </div>
  );
};

const EquipmentWarning = ({
  classes,
  record,
  history,
  translate,
  ...props
}) => {
  const handleClick = () => {
    history.push(`/campaigns/${record.id}/show/equipment`);
  };

  if (record.equipment && record.equipment.length === 0)
    return (
      <Button onClick={handleClick} className={classes.warningEquipments}>
        {translate("resources.campaigns.fields.noEquipments")}
      </Button>
    );
  return null;
};

const CustomCampaignDetails = ({ className, record, ...props }) => {
  return (
    <Map
      className={className}
      viewport={{
        center: record ? record.area : [41.15, -8.611111],
        zoom: record ? record.zoom : 6,
      }}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {record && <Marker position={record.area} />}
    </Map>
  );
};

class _NoteTagField extends Component {
  render() {
    let key = 0;
    const { classes, record } = this.props;
    return record.tags.map((tag) => {
      return (
        <span className={classes.tag_root} key={key++}>
          <span className={classes.tag_label}> {tag.name}</span>
        </span>
      );
    });
  }
}

const NoteTagField = withStyles(listStyles)(_NoteTagField);

class TextFieldLimitedSize extends Component {
  render() {
    const { record } = this.props;
    const maxLength = 30;
    if (record.description.length > maxLength) {
      return record.description.substring(0, maxLength) + "...";
    } else {
      return record.description;
    }
  }
}

const ReferenceCAA = ({ record, ...props }) => {
  const { public: _public, permLink } = record;
  if (!_public) return null;

  const url = `https://iliad-citizen.inesctec.pt/${permLink}#/`;
  return (
    <Labeled label={"CAA URL"}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    </Labeled>
  );
};

const EquipmentLaunchWarning = ({
  ids,
  history,
  record,
  parentrecord,
  ...props
}) => {
  const handleClick = () => {
    if (parentrecord) history.push(`/launches/${parentrecord.id}/show/1`);
  };

  if (ids && ids.length > 0)
    return (
      <IconButton onClick={handleClick}>
        <CheckCircleIcon style={{ color: "rgba(50,205,50)" }} />
      </IconButton>
    );

  return (
    <IconButton onClick={handleClick}>
      <ErrorOutlineIcon style={{ color: "rgba(255,0,0)" }} />
    </IconButton>
  );
};

class CampaignShowComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: 0,
      selectedIds: [],
    };

    this.selectOne = this.selectOne.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.deleteMultiple = this.deleteMultiple.bind(this);
    this.deleteMultipleNotes = this.deleteMultipleNotes.bind(this);
  }

  deleteMultiple() {
    const { selectedIds } = this.state;

    if (selectedIds.length > 0) {
      const campaignId = this.props.id;

      const { basePath, crudDeleteMany } = this.props;
      crudDeleteMany(
        `/campaigns/${campaignId}/equipment/rel`,
        selectedIds,
        {},
        basePath
      );
      this.setState({ selectedIds: [] });
    }
  }
  deleteMultipleNotes() {
    const { selectedIds } = this.state;
    const { basePath, crudDeleteMany } = this.props;

    crudDeleteMany(`notes`, selectedIds, {}, basePath);
    this.setState({ selectedIds: [] });
  }
  selectOne(e) {
    let { selectedIds } = this.state;
    const index = selectedIds.indexOf(e);
    if (index === -1) selectedIds.push(e);
    else selectedIds.splice(index, 1);

    this.setState({
      selectedIds: selectedIds,
      version: this.state.version + 1,
    });
  }

  selectAll(e) {
    this.setState((state) => ({
      selectedIds: [...e],
      version: state.version + 1,
    }));
  }

  render() {
    const {
      classes,
      translate,
      fetchStart,
      fetchEnd,
      showNotification,
      crudDeleteMany,
      history,
      ...props
    } = this.props;
    const { version, selectedIds } = this.state;

    return (
      <Show actions={<CampaignActions />} {...props}>
        <TabbedShowLayout
          tabs={
            <TabbedShowLayoutTabs
              classes={{ scrollable: classes.tabs }}
              scrollable
              scrollButtons={"on"}
            />
          }
        >
          <Tab
            className={classes.flexible}
            label="resources.campaigns.tabs.details"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <SimpleShowLayout className={classes.detailsData}>
              <TextField source="name" />
              <TextField source="description" />
              <DateField source="beginDate" format="dd/MM/yyyy" noFields />
              <GeoCoordinatesField source="area" />
              <TextField source="zoom" />
              <BooleanField source="hide" />
              <BooleanField source="public" />
              <ReferenceField source="ownerId" reference="users/public" linkType={false}>
                <TextField source="name" />
              </ReferenceField>
              <ReferenceCAA source="permlink" />
              <EquipmentWarning
                classes={classes}
                history={history}
                translate={translate}
              />
              <CustomCampaignDetails className={classes.detailsMap} />
            </SimpleShowLayout>
          </Tab>
          <Tab label="resources.campaigns.tabs.launches" path="launches">
            <LaunchesToolbar />
            <ReferenceManyField
              reference="launches"
              target="campaignId"
              addLabel={false}
              pagination={<Pagination />}
            >
              <ResponsiveDatagrid
                actions={[
                  /*<BuildButton recordId="id"/>,*/ <EditButton />,
                  <ShowButton />,
                  <MapButton />,
                ]}
              >
                <ReferenceManyManyField
                  addLabel={false}
                  reference="equipment"
                  label={translate("resources.launches.fields.equipmentAdded")}
                >
                  <EquipmentLaunchWarning history={history} />
                </ReferenceManyManyField>
                <LaunchOpenField source="state" />
                <TextField source="name" />
                {/* <TextField source='description' /> */}
                <DateField
                  source="beginTimestamp"
                  format="dd/MM/yyyy HH:mm:ss"
                />
                <DateField source="endTimestamp" format="dd/MM/yyyy HH:mm:ss" />
                <BooleanField source="public" />
                {/* <BooleanField source="autoPublish" /> */}
                {/* <ReferenceField label="Number of Equipments" source="id" reference="launches/count/equipment" linkType={false} sortable={false} allowEmpty>
                  <TextField source="count"/>
                </ReferenceField> */}
              </ResponsiveDatagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="resources.campaigns.tabs.datasets" path="datasets">
            <ReferenceManyManyField
              source="campaigns"
              reference="datasets"
              addLabel={false}
              pagination={<Pagination />}
            >
              <ListDatasets campaignC={false} />
            </ReferenceManyManyField>
          </Tab>
          <Tab label="resources.campaigns.tabs.equipment" path="equipment">
            <ReferenceManyManyField
              reference="equipment"
              addLabel={false}
              params={{
                include: {
                  relation: "launches",
                  scope: {
                    where: {
                      and: [{ endTimestamp: null }, { campaignId: props.id }],
                    },
                  },
                },
              }}
              pagination={<Pagination />}
            >
              <RelationShow
                reference={"equipment"}
                dialogContent={
                  <Relation
                    basePath="campaigns"
                    recordId={props.id}
                    pagination={<Pagination />}
                    reference={`/campaigns/${props.id}/equipment`}
                    changeIdOnRequest={"id"}
                    listFilters={
                      <LocalSearch
                        filter="serialNumber"
                        label={translate(
                          "resources.equipment.fields.serialNumber"
                        )}
                      />
                    }
                  >
                    <ResponsiveDatagrid>
                      <TextField source="name" />
                      <TextField source="number" />
                      <TextField source="serialNumber" />
                      <BooleanField source="import" />
                    </ResponsiveDatagrid>
                  </Relation>
                }
                secondPress={
                  selectedIds.length > 0 ? this.deleteMultiple : undefined
                }
                Icon={Delete}
                secondLabel={translate("pos.general.remove_button")}
              >
                <ResponsiveDatagrid
                  version={version}
                  hasBulkActions={true}
                  selectedIds={selectedIds}
                  onToggleItem={this.selectOne}
                  onSelect={this.selectAll} /*{...props}*/
                >
                  <EquipmentActiveField source="state" />
                  <TextField source="name" />
                  <TextField source="number" />
                  <TextField source="serialNumber" />
                  <BooleanField source="import" />
                </ResponsiveDatagrid>
              </RelationShow>
            </ReferenceManyManyField>
          </Tab>
          <Tab label="resources.campaigns.tabs.satellites" path="satellites">
            <SatelliteCover />
          </Tab>
          {/* <Tab label='resources.campaigns.tabs.teams' path='teams'>
            <ReferenceManyManyField source='campaigns' reference='teams' addLabel={false} pagination={<Pagination />}>
              <RelationShow reference='teams'
                dialogContent={<Relation basePath='campaigns' recordId={props.id} pagination={<Pagination />}>
                  <ResponsiveDatagrid>
                    <TextField source='name' />
                    <TextField source='code' />
                  </ResponsiveDatagrid>
                </Relation>}
              >
                <ResponsiveDatagrid actions={[<DissociateButton basePath='campaigns' basePathId={props.id} />]}>
                  <TextField source='name' />
                  <TextField source='code' />
                </ResponsiveDatagrid>
              </RelationShow>
            </ReferenceManyManyField>
          </Tab> */}
          <Tab label="resources.campaigns.tabs.overlays" path="overlays">
            <ReferenceManyManyField
              source="campaigns"
              reference="overlays"
              addLabel={false}
              pagination={<Pagination />}
            >
              <RelationShow
                reference="overlays"
                dialogContent={
                  <Relation
                    basePath="campaigns"
                    recordId={props.id}
                    pagination={<Pagination />}
                  >
                    <ResponsiveDatagrid>
                      <TextField source="name" />
                      <TextField source="opacity" />
                      <GeoCoordinatesField source="northEast" />
                      <GeoCoordinatesField source="southWest" />
                    </ResponsiveDatagrid>
                  </Relation>
                }
              >
                <ResponsiveDatagrid
                  actions={[
                    <DissociateButton
                      basePath="campaigns"
                      basePathId={props.id}
                    />,
                  ]}
                >
                  <TextField source="name" />
                  <TextField source="opacity" />
                  <GeoCoordinatesField source="northEast" />
                  <GeoCoordinatesField source="southWest" />
                </ResponsiveDatagrid>
              </RelationShow>
            </ReferenceManyManyField>
          </Tab>

          <Tab label="resources.campaigns.tabs.notes" path="notes">
            <ReferenceManyManyField
              source="campaigns"
              reference="notes"
              addLabel={false}
              params={{
                include: "tags",
              }}
              pagination={<Pagination />}
            >
              <MyRelation
                reference={"notes"}
                secondPress={
                  selectedIds.length > 0 ? this.deleteMultipleNotes : undefined
                }
                Icon={Delete}
                secondLabel={translate("pos.general.remove_button")}
              >
                <ResponsiveDatagrid
                  hasBulkActions={true}
                  version={version}
                  selectedIds={selectedIds}
                  onToggleItem={this.selectOne}
                  onSelect={this.selectAll}
                  actions={[<EditButton />, <ShowButton />]}
                >
                  <DateField source="beginTime" showTime />
                  <TextFieldLimitedSize source="description" />
                  <NoteTagField source="tags" classes={classes} />
                </ResponsiveDatagrid>
              </MyRelation>
            </ReferenceManyManyField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    );
  }
}

export const CampaignShow = compose(
  translate,
  withStyles(listStyles),
  connect(null, {
    showNotification,
    fetchEnd,
    fetchStart,
    crudDeleteMany: crudDeleteManyAction,
  })
)(CampaignShowComp);

class CampaignEditContent extends Component {
  handleClick = (param) => {
    this.props.changeForm("record-form", "area", param.latlng);
  };

  handleDrag = (param) => {
    this.props.changeForm("record-form", "area", param.target._latlng);
  };

  handleZoom = (param) => {
    this.props.changeForm("record-form", "zoom", param.target._zoom);
  };

  render() {
    const { areaPoint, changeForm, classes, translate, zoom, ...rest } =
      this.props;

    return (
      <Edit {...rest}>
        <SimpleForm redirect="show">
          <CustomCampaignForm
            classes={classes}
            areaPoint={areaPoint}
            zoom={parseInt(zoom)}
            handleClick={this.handleClick}
            handleDrag={this.handleDrag}
            handleZoom={this.handleZoom}
            onEdit={true}
          />
        </SimpleForm>
      </Edit>
    );
  }
}

const CustomCampaignForm = translate(
  ({
    areaPoint,
    children,
    classes,
    translate,
    zoom,
    handleClick,
    handleDrag,
    handleZoom,
    onEdit = false,
    ...props
  }) => {
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 0.3,
            flexDirection: "column",
            display: "flex",
            marginRight: "2em",
          }}
        >
          <TextInput source="name" validate={required()} {...props} />
          <TextInput source="description" validate={required()} {...props} />
          <DateInput
            source="beginDate"
            label={translate("resources.campaigns.fields.beginDate")}
            validate={required()}
            month={true}
            options={{
              format: "dd/MM/yyyy",
              clearable: true,
            }}
            // {...props}
            // record={{ola: ""}}
          />
          <TextInput source="zoom" validate={required()} {...props} />
          <GeoCoordinatesInput
            source="area"
            hasLabel={false}
            validate={required()}
            fieldClassName={classes.fullWidth}
            {...props}
          />
          <BooleanInput source="hide" {...props} />
          {onEdit && (
            <ReferenceInput
              label="Owner"
              source="ownerId"
              reference="users/public"
            >
              <AutocompleteInput source="username" validate={required()} />
            </ReferenceInput>
          )}

          {/* <BooleanInput source="public" {...props}/> */}
        </div>
        <Map
          style={{ flex: 0.7 }}
          zoom={zoom || 6}
          center={
            areaPoint !== undefined && areaPoint.lat && areaPoint.lng
              ? areaPoint
              : [41.15, -8.611111]
          }
          onClick={handleClick}
          onZoomEnd={handleZoom}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {areaPoint && areaPoint.lat && areaPoint.lng && (
            <Marker position={areaPoint} onDragend={handleDrag} draggable />
          )}
        </Map>
      </div>
    );
  }
);

class CampaignCreateContent extends Component {
  handleClick = (param) => {
    this.props.changeForm("record-form", "area", param.latlng);
  };

  handleDrag = (param) => {
    this.props.changeForm("record-form", "area", param.target._latlng);
  };

  handleZoom = (param) => {
    this.props.changeForm("record-form", "zoom", param.target._zoom);
  };

  render() {
    const { areaPoint, classes, translate, zoom, changeForm, ...rest } =
      this.props;
    return (
      <Create actions={<DefaultCardActions />} {...rest}>
        <SimpleForm redirect="show">
          <CustomCampaignForm
            areaPoint={areaPoint}
            zoom={parseInt(zoom)}
            handleClick={this.handleClick}
            handleDrag={this.handleDrag}
            handleZoom={this.handleZoom}
            classes={classes}
          />
        </SimpleForm>
      </Create>
    );
  }
}

const mapStateToProps = (state) => ({
  areaPoint:
    state.form &&
    state.form["record-form"] &&
    state.form["record-form"].values &&
    state.form["record-form"].values.area,
  zoom:
    state.form &&
    state.form["record-form"] &&
    state.form["record-form"].values &&
    state.form["record-form"].values.zoom,
});

const enhance = compose(
  translate,
  withStyles(listStyles),
  connect(mapStateToProps, {
    changeForm: changeAction,
  })
);

export const CampaignEdit = enhance(CampaignEditContent);
export const CampaignCreate = enhance(CampaignCreateContent);
