import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
  Button,
  Title,
  crudGetOne as crudGetOneAction,
  fetchEnd,
  fetchStart,
  showNotification,
  translate
} from 'react-admin';
import { Link } from 'react-router-dom';
import {
  ShowChart as ChartIcon,
  ViewList as TableIcon,
  List as DatasetIcon
} from '@material-ui/icons';
import { Typography } from '@material-ui/core';

import { annotationsGeoJSON, toObservationDataSingleWAVY } from '../../factory';
import dataFetch from '../../providers/dataFetch';
import TimeControlledMap from '../../components/map/TimeControlledMap';
import { refreshAnnotations } from './actions'

class DerivedSetMap extends Component {

  state = {
    derivedsetData: null,
    geoObservations: undefined,
    observationsLoaded: false,
    annotationsLoaded: false,
    originalObservations: undefined,
    load: false,
  }
  getModelProperties = equipmentId => {
    return new Promise((resolve, reject) => {

      dataFetch('GET', `/equipment/${equipmentId}`)
        .then((equipment) => {
          const filter = JSON.stringify({
            include: 'observedproperty',
            where: { equipmentmodelId: equipment.equipmentmodelId }
          });
          return dataFetch('GET', `/modelproperties?filter=${filter}`)
        })
        .then(data => {
          if (data) {
            resolve(data)
          } else {
            reject();
          }
        })
        .catch(e => reject(e));
    });
  };

  loadData = () => {
    const { fetchEnd, fetchStart, match } = this.props;
    fetchStart();
    let _observations, geoData;
    dataFetch('GET', `/derivedsets/${match.params.id}/observations?filter=${JSON.stringify({ where: { record: 0 }, limit: 0, order: 'timestamp ASC' })}`)
      .then(observations => {
        _observations = observations;

        return this.getModelProperties(this.props.derivedset.equipmentId)
      })
      .then(properties => {
        geoData = toObservationDataSingleWAVY({
          observations: _observations,
          observedProperties: properties
        });

        this.setState({ geoObservations: geoData, originalObservations: _observations, derivedsetData: geoData, observationsLoaded: true });
        this.props.refreshAnnotations(match.params.id);
      })
      .catch(err => {
        console.error(err)
        this.props.showNotification('containers.derivedsets.map.loadObsError', 'warning');
      })
      .finally(_ => {
        fetchEnd();
      });
  }

  componentDidMount() {
    const { crudGetOne, derivedset, match } = this.props;

    if (!derivedset) {
      crudGetOne('derivedsets', match.params.id, 'derivedsets');
    }
    else {
      this.processData();
    }
  }

  processData = () => {
    this.setState({ load: true });
    this.loadData();
  }

  componentDidUpdate(oldProps) {
    const { derivedset } = this.props;
    const { load } = this.state;

    if (derivedset && !load) {
      this.processData();
    }
    // if (this.state.observationsLoaded && this.props.annotationsAction && JSON.stringify(oldProps.annotationsAction) !== JSON.stringify(this.props.annotationsAction)) {
    if (this.state.observationsLoaded && this.props.annotationsAction && oldProps.annotationsAction !== this.props.annotationsAction) {
      
      const geoData = { ...this.state.geoObservations };
      // const observations = {...this.state.observations};
      let obs_object = {};
      this.state.originalObservations.forEach(({ position, timestamp, serialNumber }) => {
        obs_object[new Date(timestamp).getTime()] = {
          position,
          serialNumber
        };
      });
      const tagsGeoJSON = annotationsGeoJSON(obs_object, this.props.annotationsAction);
      geoData.features = geoData.features.concat(tagsGeoJSON);

      this.setState({ derivedsetData: geoData, annotationsLoaded: true, observationsLoaded: false });
    }

    if (this.state.geoObservations && this.props.annotationsAction && JSON.stringify(oldProps.annotationsAction) !== JSON.stringify(this.props.annotationsAction)) {
      const geoData = { ...this.state.geoObservations };
      // const observations = {...this.state.observations};
      let obs_object = {};
      this.state.originalObservations.forEach(({ position, timestamp, serialNumber }) => {
        obs_object[new Date(timestamp).getTime()] = {
          position,
          serialNumber
        };
      });
      const tagsGeoJSON = annotationsGeoJSON(obs_object, this.props.annotationsAction);
      geoData.features = geoData.features.concat(tagsGeoJSON);

      this.setState({ derivedsetData: geoData, annotationsLoaded: true, observationsLoaded: false });
    }
  }

  render() {
    const { translate, derivedset, match } = this.props;
    const { derivedsetData, annotationsLoaded } = this.state;

    return <div style={{ display: 'flex', flex: '1', flexDirection: 'column' }}>
      <Title title={translate('containers.derivedsets.map.name')} />
      <div style={{ marginBottom: '8px', display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant='title' >{derivedset && derivedset.name}</Typography>
        <div>
          <Button style={{ marginRight: '8px' }} component={Link} label='pos.general.chart' to={`/derivedsets/${derivedset && derivedset.id}/charts`}><ChartIcon /></Button>
          <Button style={{ marginRight: '8px' }} component={Link} label='pos.general.table' to={`/derivedsets/${derivedset && derivedset.id}/show`}><TableIcon /></Button>
          <Button component={Link} label={translate('pos.general.list', { smart_count: 2 })} to={'/derivedsets'}><DatasetIcon /></Button>
        </div>
      </div>
      <TimeControlledMap annotation={true} refreshData={() => this.props.refreshAnnotations(match.params.id)} recordId={match.params.id} geodata={annotationsLoaded ? {...derivedsetData}: undefined} legend={derivedset && derivedset.name} />
    </div>
  }
}

const mapStateToProps = (state, props) => ({
  derivedset: state.admin.resources.derivedsets.data[props.match.params.id],
  annotationsAction: state.annotations
});

const enhance = compose(
  translate,
  connect(mapStateToProps, {
    crudGetOne: crudGetOneAction,
    fetchEnd,
    showNotification,
    fetchStart,
    refreshAnnotations
  })
);

export default enhance(DerivedSetMap);