import React, { Component } from 'react';
import {
  Title,
  translate,
  fetchEnd,
  fetchStart,
  showNotification,
} from 'react-admin';

import TimelineControl from 'leaflet-timeline';

import { compose } from 'recompose';
import { connect } from 'react-redux';

import L from 'leaflet';
import moment from 'moment';
import {
  dataFetch,
} from '../../providers';
import LeafletMap from '../../components/map/LeafletMap';
import CampaignControl from './CampaignControl';
import {
  fetchObservations as fetchObservationsAction,
} from './actions';

class History extends Component {
  state = {
    viewport: {
      center: [41.1619, -8.5835],
      zoom: 5
    },
    controlLayer: null,
    launchId: null
  };

  getObservations = (launchId) => {
    return new Promise((resolve, reject) => {
      const url = `/observations/launch/${launchId}?filter=${JSON.stringify({ where: { record: 0 }, order: "timestamp ASC" })}`;
      dataFetch('GET', url)
        .then(data => resolve(data))
        .catch(err => reject(err));
    });
  };

  getModelProperties = (datasetIds) => {
    return new Promise((resolve, reject) => {
      const filter = {
        include: 'equipment',
        where: { id: { inq: datasetIds } }
      };

      dataFetch('GET', `/datasets?filter=${JSON.stringify(filter)}`)
        .then(data => {
          if (data) {
            const equipmentModelIds = [...new Set(data.map(item => item.equipment.equipmentmodelId))];
            const filter = JSON.stringify({
              include: 'observedproperty',
              where: { equipmentmodelId: { inq: equipmentModelIds } }
            });
            dataFetch('GET', `/modelproperties?filter=${filter}`)
              .then((result) => {
                let datasets = {};
                data.forEach(dataset => {
                  datasets[dataset.id] = result.filter(item => {
                    if (item.equipmentmodelId === dataset.equipment.equipmentmodelId)
                      return item;
                    else
                      return null;
                  })
                });
                resolve(datasets);
              }).catch(err => reject(err));
          } else {
            resolve();
          }
        });
    });
  }

  getData = (launchId) => {
    const { fetchStart, fetchEnd, fetchObservations, showNotification } = this.props;

    fetchStart();

    this.getObservations(launchId)
      .then(observations => {
        // Get unique datasetIds
        const datasetIds = [...new Set(observations.map(item => item.datasetId))];
        this.getModelProperties(datasetIds)
          .then(data => {
            fetchObservations({
              observations: observations,
              observedProperties: data
            });
            observations.length === 0 && showNotification('containers.maps.history.info.no_data')
          });
      })
      .finally(_ => {
        fetchEnd();
      });
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.props.fetchObservations({
      observations: [],
      observedProperties: {}
    });
    if (params?.launchId) {
      this.getData(params.launchId);
      this.setState({ launchId: params.launchId });
    }
  }

  render() {
    const { activeCampaign, translate, observations, overlays } = this.props;
    const { controlLayer, viewport } = this.state;

    const timelineControlOptions = {
      timelineOptions: {
        marker: (coordinates, properties) => {
          let marker = L.marker(coordinates, {
            icon: L.ExtraMarkers.icon({
              icon: properties.id.length > 4 ? 'fb-number' : 'fa-number',
              markerColor: 'blue',
              prefix: 'fa',
              number: `${properties.id.substr(0, 2)}\n${properties.id.substr(2, properties.id.length)}`,
            })
          });
          return marker;
        },
        markerPopup: function (properties) {
          let popup = `<h3>${properties.id}</h3>`;
          if (properties.timestamp) {
            popup += `<div>time: ${moment(properties.timestamp).utc().format('DD-MM-YYYY HH:mm:ss')} UTC</div>`
          }
          const hiddenProperties = ['gpstime', 'timestamp', 'position', 'properties', 'serialNumber', 'id', 'key'];
          Object.keys(properties).map((key) => {
            // In order to present the property must have the "value" prop otherwise ignore
            if (!hiddenProperties.includes(key) && properties[key].value) {
              const prop = properties[key].value + (properties[key].unit ? ` ${properties[key].unit}` : '');
              const value = properties[key].value ? prop : translate('containers.properties.no_data');
              return popup += `<div>${key}: ${value}</div>`;
            }
            return null;
          });

          return popup;
        }
      },
      timecontrolOptions: {
        legend: activeCampaign ? activeCampaign.name : '',
        showTicks: false,
        speeds: [1, 2, 4, 8, 16, 32, 64],
        formatOutput: (date) => {
          return moment(date).utc().format('DD-MM-YYYY HH:mm:ss') + ' UTC';
        }
      }
    };

    return (
      <div style={{ display: 'flex', flex: '1' }}>
        <Title title={translate('containers.maps.history.name')} />
        <LeafletMap viewport={viewport} sendCtrlLayer={(layer) => this.setState({ controlLayer: layer })} campaignOverlays={overlays}>
          {!this.state.launchId && <CampaignControl />}
          {observations && observations.features.length > 0 && controlLayer && <TimelineControl geoJSON={observations} ctrlLayer={controlLayer} {...timelineControlOptions} />}
        </LeafletMap>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  observations: state.fetchedObservations,
  overlays: state.overlays,
  activeCampaign: state.selectedCampaign
});

const mapDispatchToProps = dispatch => ({
  fetchObservations: data => dispatch(fetchObservationsAction(data)),
  fetchEnd: () => dispatch(fetchEnd()),
  fetchStart: () => dispatch(fetchStart()),
  showNotification: error => dispatch(showNotification(error))
});

const enhance = compose(
  translate,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(History);