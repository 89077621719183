import React from 'react';
import {
  Create,
  Show,
  ShowButton,  
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';


import {
  Edit,
  DefaultCardActions,
  ResponsiveList
} from '../../components';

export { default as ObservedPropertiesIcon } from '@material-ui/icons/EventNote'; 

export const ObservedPropertiesList = ({...props}) => {
  return <ResponsiveList
    {...props}
    sort={{field: 'name', order: 'ASC'}}
    recordActions={[<ShowButton/>]}
    rowClick="show"
  >
    <TextField source='name'/>
    <TextField source='description'/>
  </ResponsiveList>
}

export const ObservedPropertiesCreate = ({...props}) => {
  return <Create actions={<DefaultCardActions/>} {...props}>
    <SimpleForm>
      <TextInput source='name' />
      <TextInput source='description' />
    </SimpleForm>
  </Create>

}

export const ObservedPropertiesEdit = ({...props}) => {
  return <Edit actions={<DefaultCardActions/>} {...props}>
    <SimpleForm>
      <TextInput source='name' />
      <TextInput source='description' />
    </SimpleForm>
  </Edit>
}

export const ObservedPropertiesShow = ({...props}) => (
  <Show actions={<DefaultCardActions {...props} hasCreate={false}/>} {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="description" />
    </SimpleShowLayout>
  </Show>
)